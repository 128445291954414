h1 {
	font-size: em(40);
	font-weight: 400;
	line-height: 120%;
	color: var(--white);

	@include mq(md) {
		font-size: em(70);
	}

	strong {
		font-weight: 700;
	}
}

h2 {
	font-size: em(14);
	font-weight: 300;
	text-transform: uppercase;
	color: var(--white);
	margin-bottom: 5px;
	margin-top: -90px;
	padding-top: 90px;

	strong {
		font-weight: 700;
	}

	@include mq(md) {
		font-size: em(16);
	}
}

h3 {
	font-size: em(32);
	font-weight: 300;
	line-height: em(12);
	margin: 0 0 10px 0;
	line-height: 125%;
	color: var(--white);

	strong {
		font-weight: 700;
	}

	@include mq(md) {
		font-size: em(50);
	}
}

h4 {
	font-size: em(20);
	font-weight: 400;
	line-height: em(12);
	margin-top: 10px;
	color: var(--white);

	@include mq(md) {
		font-size: em(40);
	}
}

p {
	margin: 15px 0;
	line-height: 150%;
	color: var(--white);
	@include mq(md) {
		font-size: em(18);
	}
}

p,
li,
table {
	font-size: em(16);

	li {
		font-size: 1em;
	}
}

.bg-gradient {
	height: 50px;
	width: 100%;

	@include mq(md) {
		height: 75px;
	}

	&.gradient-azul-ec {
		background: linear-gradient(90deg, #2795b8 0%, #02739a 100%);
	}

	&.gradient-azul-ce {
		background: linear-gradient(90deg, #1988ad 0%, #03739a 100%);
	}

	&.gradient-verde-ec {
		background: linear-gradient(-90deg, #209f51 0%, #019147 100%);
	}

	&.gradient-verde-ce {
		background: linear-gradient(90deg, #209f51 0%, #019147 100%);
	}

	&.gradient-vermelho-ec {
	}

	&.gradient-vermelho-ce {
		background: linear-gradient(90deg, #f06071 0%, #c44250 100%);
	}
}
