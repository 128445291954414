.conteudos__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  position: relative;
  z-index: 1;

  @include mq(md) {
    grid-template-columns: 2fr 1fr;
  }
}

.conteudos-recentes {
  width: calc(100vw - 60px);

  @include mq(md) {
    width: auto;
  }
  &__item {
    display: flex;
    border: 1px solid #186EFF50;
    border-radius: 40px;
    background-image: linear-gradient(30deg, #071D5630 0%, #3343A130 100%);
    padding: 10px;
    align-items: center;
    position: relative;
    flex-direction: column;
    

    @include mq(md) {
      flex-direction: row;

    }

    a {
      text-decoration: none;
      color: inherit;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        z-index: 2;
      }
      
    }

    img {
      border-radius: 35px;
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    h4 {
      color: var(--light-blue);
    }

    h3 {
      text-align: center;

      @include mq(md) {
        text-align: left;
      }
    }

    &:first-child {
      img {
        display: block;
      }
    }
  }

  &__lista {
    grid-gap: 30px;
    display: flex;
    flex-direction: column;
  }

  &__ico {
    position: absolute;
    right: 30px;
    top: 30px;
    border-radius: 0 !important;
  }

  &__info {
    padding: 30px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: normal;
    

    @include mq(md) {
      padding: 0 30px;
    }
    h3 {
      text-align: left;
    }

    p {
      overflow: hidden;

      @include mq(md) {
        overflow: unset;
      }

      &:empty {
        display: none;
      }
    }
  }

  .alm-reveal {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
  }
}