.bg-video {
	position: fixed;
	background: transparent;
	top: 0px;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 200;
}
.bg-video.active {
	display: flex;
}
video {
	width: 90%;
	max-width: rem(1170);
	max-height: 90vh;
}

.container-video {
	position: relative;
	width: 480px;
	background: #fff;
	border-radius: 40px;
	padding: 15px 35px 0px 35px;
	text-align: center;

	label {
		text-align: left;
	}

	form input {
		padding: 10px;
		color: var(--dark-blue) !important;
	}

	form .wpcf7-submit {
		color: #fff !important;
		border: 1px solid #186eff50;
		padding: 18px 90px 18px 30px;
		font-weight: 500;
		margin: 0 auto;
		margin-top: 40px;
		display: flex;
		align-items: center;
		transition: 0.2s;
		grid-gap: 50px;
		border-radius: 50px;
		text-decoration: none;
		cursor: pointer;
		background-color: var(--light-blue) !important;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.333' height='13.333' viewBox='0 0 13.333 13.333'%3E%3Cg id='Icon' transform='translate(0.667 0.667)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon'%3E%3Cpath id='_5452ea59-c4a9-4a1c-a94e-b6a87f9da105' data-name='5452ea59-c4a9-4a1c-a94e-b6a87f9da105' d='M14,10v2.667A1.333,1.333,0,0,1,12.667,14H3.333A1.333,1.333,0,0,1,2,12.667V10' transform='translate(-2 -2)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.333'/%3E%3Cpath id='f90f515d-5c04-416e-b679-b4ed3265cf98' d='M4.667,6.667,8,10l3.333-3.333' transform='translate(-2 -2)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.333'/%3E%3Cline id='_8d0c73f7-abc2-4bc2-a312-3e0a6648c336' data-name='8d0c73f7-abc2-4bc2-a312-3e0a6648c336' y1='8' transform='translate(6)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.333'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
			linear-gradient(0deg, var(--blue04) 0%, var(--light-blue) 100%);
		background-position: calc(100% - 30px) center, center;
		background-repeat: no-repeat;
	}

	a {
		color: var(--blue01);
	}
}

.text-download {
	display: block;
}

.download-inicio,
#player-video {
	display: none;
}

.wpcf7-form.sent {
	.download-inicio,
	#player-video {
		display: inline-block;
	}
	.wpcf7-response-output,
	.text-download {
		// display: none;
	}
}

video {
	width: 100%;
	height: 100%;
}

.bt-close {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.6' height='17.6' viewBox='0 0 17.6 17.6'%3E%3Cg id='Icon' transform='translate(2.09 3.299)'%3E%3Ccircle id='d4c8f9f7-aec8-422a-a951-dfc4a7f52707' cx='8' cy='8' r='8' transform='translate(-1.29 -2.499)' fill='none' stroke='%238f9cc6' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3Cline id='_9caa2eda-be09-4268-8a43-784976e1e188' data-name='9caa2eda-be09-4268-8a43-784976e1e188' x1='4.8' y2='4.8' transform='translate(4.31 3.101)' fill='none' stroke='%238f9cc6' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3Cline id='dde34fe4-4456-4b21-846c-338289ba4c44' x2='4.8' y2='4.8' transform='translate(4.31 3.101)' fill='none' stroke='%238f9cc6' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center;
	width: 40px;
	height: 40px;
	border: 0;
	background-color: transparent;
	position: absolute;
	right: 15px;
	top: 15px;
	font-size: 0;
	cursor: pointer;
}

// .bloco-modal-ativo {
//   overflow: hidden;
// }
