.header {
	position: fixed;
	top: 20px;
	width: 100vw;
	z-index: 1000;

	&__logo {
		align-items: center;
		display: flex;
		width: 105px;
		background: unset;
		justify-content: center;
		border-top-left-radius: 100px;
		border-bottom-left-radius: 100px;
	}

	&__wrapper {
		background: var(--green02);
		height: 50px;
		border-radius: 100px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		color: #fff;
		align-items: stretch;
		width: calc(100vw - 30px);
		position: relative;
		filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.25));
	}

	.hamburger,
	.folha {
		display: inline-block;
	}

	.folha {
		display: flex;
		align-items: center;
	}

	.wrapper__large {
		padding: 0 15px;
	}
}

@include mq(md) {
	.header {
		&__logo {
			width: 180px;
			background: var(--green01);
		}
		&__wrapper {
			height: 50px;
			padding: 0 5px 0 0;
			width: fit-content;
			margin: 0 auto;
			background: unset;
		}
		.hamburger,
		.folha {
			display: none;
		}
		.wrapper__large {
			padding: 0 30px;
		}
	}
}
