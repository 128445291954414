.parceiro {
	&__comofunciona {
		margin: 60px 0;

		h2,
		h3,
		p {
			color: var(--red01);
		}

		&-title {
			text-align: center;

			@include mq(md) {
				h3 {
					width: 50%;
					margin: 0 auto;
				}
			}
		}

		&-aditivos {
			--animation-time: 0.2s;
			margin: 30px 0;

			.aditivo {
				&-cards {
					width: fit-content;

					.box-outside {
						display: flex;
						justify-content: center;
					}
				}

				&-divisor {
					display: flex;
					align-items: center;
					padding: 0 10px;
				}

				&-wrapper {
					scrollbar-width: none;
					overflow: scroll visible;
					padding: 0 20px;
				}

				&-card {
					transition: all var(--animation-time);
					height: inherit;
					background-size: 200% 0%;
					border-radius: 32px;
					padding: 15px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 187px;
					height: 223px;
					display: flex;
					position: relative;
					background: linear-gradient(
						90deg,
						#f05f70 12%,
						#c43c4a 100%
					);

					.box {
						display: flex;
						align-items: center;
						flex-direction: column;
						border-radius: 24px;
						height: 100%;
						justify-content: center;
						padding: 7px;

						svg {
							transition: all var(--animation-time);
							// transform: translateY(30px);
							transition-delay: var(--animation-time);
						}

						p {
							color: var(--white);
							opacity: 0;
							transition: opacity var(--animation-time)
									ease-in-out,
								max-height var(--animation-time) ease-in-out;
							text-align: center;
							visibility: hidden;
							overflow: hidden;
							margin: 0;
							font-size: em(12);
							max-height: 0;
						}
					}

					&:hover {
						transition: all var(--animation-time);

						.box {
							background: var(--red02);

							svg {
								transition: all var(--animation-time);
								transform: translateY(-10px);
							}

							p {
								opacity: 1;
								visibility: visible;
								transition: opacity var(--animation-time)
										ease-in-out,
									max-height var(--animation-time) ease-in-out;
								// transition-delay: 0.1s;
								transform: translateY(10px);
								max-height: 999px;
							}
						}
					}
				}
			}
		}

		&-fairfood {
			img {
				margin-bottom: 30px;
			}
		}

		&-processo {
			h3 {
				text-align: center;
				font-size: em(28);
			}

			.swiper {
				position: relative;
				margin-top: 50px;
				height: 600px;

				.swiper-slide {
					.arrow2 {
						position: absolute;
						margin-left: 118px;
						margin-top: -150px;
						width: calc(
							138px + (221 - 138) *
								((100vw - 320px) / (414 - 320))
						);
						max-width: 221px;
						min-width: 138px;
						height: 166px;
						background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 222 216" fill="none"%3E%3Cpath d="M222.354 4.35355C222.549 4.15829 222.549 3.84171 222.354 3.64645L219.172 0.464468C218.976 0.269206 218.66 0.269206 218.464 0.464468C218.269 0.65973 218.269 0.976313 218.464 1.17157L221.293 4L218.464 6.82843C218.269 7.02369 218.269 7.34027 218.464 7.53554C218.66 7.7308 218.976 7.7308 219.172 7.53554L222.354 4.35355ZM222 3.5L21 3.50011L21 4.50011L222 4.5L222 3.5ZM0.500009 24.0001L0.500014 240L1.50001 240L1.50001 24.0001L0.500009 24.0001ZM21 3.50011C9.67816 3.50011 0.500008 12.6783 0.500009 24.0001L1.50001 24.0001C1.50001 13.2306 10.2304 4.50011 21 4.50011L21 3.50011Z" fill="%23C44250"%3E%3C/path%3E%3C/svg%3E');
						background-size: cover;
					}
					.arrow {
						margin-left: 150px;
						display: inline-block;
						width: calc(
							105px + (188 - 105) *
								((100vw - 320px) / (414 - 320))
						);
						height: 198px;
						min-width: 105px;
						max-width: 188px;
						background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188 198" fill="none"%3E%3Cpath d="M187.354 194.354C187.549 194.158 187.549 193.842 187.354 193.646L184.172 190.464C183.976 190.269 183.66 190.269 183.464 190.464C183.269 190.66 183.269 190.976 183.464 191.172L186.293 194L183.464 196.828C183.269 197.024 183.269 197.34 183.464 197.536C183.66 197.731 183.976 197.731 184.172 197.536L187.354 194.354ZM0.5 0V174H1.5V0H0.5ZM21 194.5H187V193.5H21V194.5ZM0.5 174C0.5 185.322 9.67816 194.5 21 194.5V193.5C10.2304 193.5 1.5 184.77 1.5 174H0.5Z" fill="%23C44250"%3E%3C/path%3E%3C/svg%3E');
						background-size: contain;
						background-repeat: no-repeat;
						top: -16px;
						position: relative;
					}
					.processo {
						// position: relative;
						// margin-bottom: 30px;
						--pl: 20px;
						width: 292px;
						height: 272px;

						img {
							border: 3px solid var(--red02);
							border-radius: 15px;
							padding: 12px;
							background: var(--white);
							position: absolute;
							top: 0;
							left: calc(var(--pl) + 10px);
						}

						p {
							border: 1px solid var(--red02);
							border-radius: 20px;
							padding: 75px 34px 0px var(--pl);
							height: 100%;
							font-size: 1.5em;
						}
					}

					&:nth-child(2n) {
						top: 300px;
					}
				}

				&-arrows {
					display: flex;
					justify-content: center;
					gap: 5px;
					margin-top: 30px;
					z-index: 10;

					& > div {
						border: 1px solid var(--red01);
						border-radius: 50%;
						width: 28px;
						height: 28px;
						transition: all 0.2s;
						position: unset;
						top: unset;
						bottom: -10%;
						margin: 0;
						transform: unset;

						&:after {
							transition: all 0.2s;
							font-size: 10px;
							font-weight: 700;
							color: var(--red01);
						}

						&:hover {
							transition: all 0.2s;
							background: var(--red01);

							&:after {
								transition: all 0.2s;
								color: var(--white);
							}
						}

						&.swiper-button-next {
							right: unset;
						}

						&.swiper-button-prev {
							left: unset;
						}
					}
				}
			}
		}
	}
}

@include mq(md) {
	.parceiro {
		&__comofunciona {
			&-aditivos {
				margin: 30px 0 60px;

				.aditivo {
					&-card {
						padding: 0;
						background: none;

						.box {
							padding: 10px;
						}
					}

					&-cards {
						margin: 0 auto;
						border-radius: 32px;
						background: linear-gradient(
							90deg,
							#f05f70 12%,
							#c43c4a 100%
						);
						padding: 15px 20px;
						width: 100%;
					}

					&-divisor {
						svg {
							width: 11px;

							path {
								fill: rgba(248, 248, 248, 0.8);
							}
						}
					}

					&-wrapper {
						max-width: em(1160);
						margin: 0 auto;
					}
				}
			}

			&-fairfood {
				.wrapper {
					display: flex;
					flex-direction: row-reverse;
					gap: 200px;
				}

				img {
					margin-bottom: 0;
				}
			}

			&-processo {
				.swiper {
					.swiper-slide {
						.arrow2 {
							width: calc(
								202px + (224 - 202) *
									((100vw - 1024px) / (1400 - 1024))
							);
							height: 166px;
							min-width: 202px;
							max-width: 224px;
							background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202 216" fill="none"%3E%3Cpath d="M201.354 4.35355C201.549 4.15829 201.549 3.84171 201.354 3.64645L198.172 0.464468C197.976 0.269206 197.66 0.269206 197.464 0.464468C197.269 0.65973 197.269 0.976313 197.464 1.17157L200.293 4L197.464 6.82843C197.269 7.02369 197.269 7.34027 197.464 7.53554C197.66 7.7308 197.976 7.7308 198.172 7.53554L201.354 4.35355ZM1.00001 24.0001L1.50001 24.0001L1.00001 24.0001ZM201 3.5L21 3.50009L21 4.50009L201 4.5L201 3.5ZM0.50001 24.0001L0.500016 216L1.50002 216L1.50001 24.0001L0.50001 24.0001ZM21 3.50009C9.67816 3.5001 0.50001 12.6783 0.50001 24.0001L1.50001 24.0001C1.50001 13.2305 10.2304 4.5001 21 4.50009L21 3.50009Z" fill="%23C44250"%3E%3C/path%3E%3C/svg%3E');
						}
						.arrow {
							width: calc(
								172px + (193 - 172) *
									((100vw - 1024px) / (1400 - 1024))
							);
							max-width: 193px;
							min-width: 172px;
							height: 200px;
							aspect-ratio: 188 / 198;
							background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="188" height="198" viewBox="0 0 188 198" fill="none"%3E%3Cpath d="M187.354 194.354C187.549 194.158 187.549 193.842 187.354 193.646L184.172 190.464C183.976 190.269 183.66 190.269 183.464 190.464C183.269 190.66 183.269 190.976 183.464 191.172L186.293 194L183.464 196.828C183.269 197.024 183.269 197.34 183.464 197.536C183.66 197.731 183.976 197.731 184.172 197.536L187.354 194.354ZM0.5 0V174H1.5V0H0.5ZM21 194.5H187V193.5H21V194.5ZM0.5 174C0.5 185.322 9.67816 194.5 21 194.5V193.5C10.2304 193.5 1.5 184.77 1.5 174H0.5Z" fill="%23C44250"%3E%3C/path%3E%3C/svg%3E');
						}
					}
				}
			}
		}
	}
}
