.post-relacionados {
  color: #fff;
  margin: 80px 0;

  h2 {
    text-align: center;

    strong {
      font-weight: 700;
      color: #fff;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
    width: 100vw;
    margin: 0 -30px;

    @include mq(md) {
      grid-template-columns: 1fr 2fr;
      margin: 0;
      width: auto;
    }

  }

  

  &__thumb {
    width: 260px;
    height: 260px;
    margin: 0;
    flex: 0 0 260px;
    display: none;

    @include mq(md) {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 35px;
    }
    
  }

  &__info {
    @include mq(md) {
      padding-left: 30px;
    }

    p {
      display: none;

      @include mq(md) {
        display: block;
      }
    }
  }

  &__card {
    display: flex;
    border: 1px solid #186EFF50;
    border-radius: 40px;
    background-image: linear-gradient(30deg, #071D5630 0%, #3343A130 100%);
    padding: 30px;
    
    align-items: center;
    position: relative;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;
    height: 100%;
    justify-content: center;

    @include mq(md) {
      flex-direction: row;
      padding: 10px;
    }

    h3 {
      text-align: left;
    }

    h4 {
      // color: var(--blue04);
      color: var(--light-blue);
    }

    p:empty {
      display: none;
    }

    a {

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        z-index: 2;
      }
      
    }
  }

  &__proximo {
    display: flex;
    flex-direction: column;
    
    .post-relacionados__card {
      flex-direction: column;
      align-items: flex-start;
      border-radius: 25px 0 0 25px;
      margin-left: 30px;

      @include mq(md) {
        border-radius: 40px;
        margin-left: 0px;
        flex-direction: row;
        align-items: center;
      }
    }

    .post-relacionados__bt {
      align-self: flex-end;
      display: flex;
      align-items: center;
      margin-right: 30px;

      @include mq(md) {
        margin-right: 0;
      }

      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14.829' viewBox='0 0 12 14.829'%3E%3Cg id='Icon' transform='translate(0 1.414)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='dd2da0de-d5a7-4b77-a076-c5e0e5b6ee9c' d='M9,18l6-6L9,6' transform='translate(-7.29 -8.499)' fill='none' stroke='%237990E5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 12px;
        display: block;
        background-size: contain;
        margin-left: 10px;
      }
    }
  }

  &__anterior {
    display: flex;
    flex-direction: column;

    .post-relacionados__card {
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0 25px 25px 0;
      margin-right: 30px;

      @include mq(md) {
        border-radius: 40px;
        margin-right: 0px;
      }
    }

    

    .post-relacionados__bt {
      display: flex;
      align-items: center;
      margin-left: 30px;

      @include mq(md) {
        margin-left: 0;
      }

      &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14.829' viewBox='0 0 12 14.829'%3E%3Cg id='Icon' transform='translate(0 1.414)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_464c763f-989e-4074-849d-34414438503f' data-name='464c763f-989e-4074-849d-34414438503f' d='M15,18,9,12l6-6' transform='translate(-7.29 -8.499)' fill='none' stroke='%237990E5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 12px;
        display: block;
        background-size: contain;
        margin-right: 10px;
      }
    }
    // display: flex;
    // border: 1px solid #186EFF50;
    // border-radius: 40px;
    // background-image: linear-gradient(30deg, #071D5630 0%, #3343A130 100%);
    // padding: 10px;
    // align-items: flex-start;
    // position: relative;
    // flex-direction: column;

    // @include mq(md) {
    //   flex-direction: column;
    //   justify-content: center;
    // }
  }

}
