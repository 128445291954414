.nav {
	display: none;
	grid-gap: 40px;
	align-items: stretch;

	@include mq(md) {
		display: flex;
	}

	&.is--active {
		display: flex;
		position: absolute;
		background: var(--green02);
		border-radius: 15px;
		padding: 30px 20px;
		margin-top: 5px;
		top: 65px;
		left: 0px;
		flex-direction: column;
		width: calc(100vw - 30px);
		align-items: flex-start;
		align-items: center;

		@include mq(md) {
			position: initial;
			background: transparent;
			backdrop-filter: none;
			-webkit-backdrop-filter: none;
			padding: 0;
			border-radius: 0;
			margin-top: 0;
			top: none;
			left: none;
			flex-direction: row;
			width: auto;
		}
	}

	&__menu {
		display: flex;
		align-items: stretch;
		align-items: center;
	}

	ul {
		display: flex;
		align-items: stretch;
		height: 100%;
		flex-direction: column;
		gap: 5px;
		@include mq(md) {
			flex-direction: row;
			gap: 0;
		}

		li {
			display: flex;
			align-items: stretch;
			font-size: em(16);
			position: relative;
			flex-direction: column;
			align-items: center;
			background: var(--green02);
			&:nth-last-child(2) {
				border-top-right-radius: 100px;
				border-bottom-right-radius: 100px;
				@include mq(md) {
					padding-right: 5px;
					padding-left: 5px;
				}
			}
			&:last-child {
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				@include mq(md) {
					width: 50px;
					height: 50px;
					margin-left: 12px;
					flex-direction: column;
				}
				a {
					padding: 0;
					display: none;
					@include mq(md) {
						display: block;
						opacity: 1;
					}
				}
			}
			&:not(:nth-last-of-type(-n + 3)) {
				position: relative;
				&.current_page_item,
				&.current_page_parent {
					&:before {
						content: "";
						width: 30px;
						height: 3px;
						left: calc(50% - 15px);
						position: absolute;
						top: 0;
						background: var(--white);
					}
					a {
						opacity: 1;
					}
				}
				&:hover {
					&:before {
						width: 30px;
						left: calc(50% - 15px);
					}
				}
				&:before {
					content: "";
					width: 0;
					height: 3px;
					left: 50%;
					transition: all ease-in-out 0.2s;
					position: absolute;
					top: 0;
					background: var(--white);
				}
			}

			&:nth-last-of-type(-n + 3):not(:last-of-type) {
				&.current_page_item,
				&.current_page_parent {
					a {
						background: var(--white);
						opacity: 1;
						border-radius: 100px;
						height: 40px;
						color: var(--green02);
						font-weight: 700;
						border: 3px solid var(--white);
						padding: 0 15px;
					}
				}
				a {
					border: 3px solid transparent;
					height: 50px;
					border-radius: 100px;
					transition: 0.2s;
					@include mq(md) {
						height: 40px;
					}

					&:hover {
						background: var(--white);
						color: var(--green02);
						border: 3px solid var(--white);
						opacity: 1;
						border-radius: 100px;
						font-weight: 700;
						transition: 0.2s;
						@include mq(md) {
							color: var(--green02);
						}
					}
				}
			}

			@include mq(md) {
				flex-direction: row;
				font-size: em(14);
				margin-bottom: 0px;
			}
		}

		a {
			color: #fff;
			text-decoration: none;
			display: flex;
			align-items: center;
			padding: 0;
			transition: 0.2s;
			position: relative;
			border-top: 0 solid var(--white);
			opacity: 0.7;
			font-weight: 700;
			height: 40px;
			align-content: center;
			@include mq(md) {
				padding: 0 20px;
				text-align: center;
			}
		}

		.sub-menu {
			display: flex;
			position: relative;
			padding: 10px 10px 0;
			top: auto;
			height: auto;
			white-space: nowrap;
			grid-gap: 30px;
			flex-direction: row;

			@include mq(md) {
				flex-direction: column;
				display: none;
				position: absolute;
				top: 0;
				padding: 0;
				grid-gap: 0;
				cursor: default;
			}

			&:before {
				@include mq(md) {
					display: block;
					content: "";
					width: 100%;
					background: var(--green01);
					border-radius: 100px;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			li {
				font-size: em(10);
				margin-bottom: 0;
				border-radius: 50%;
				@include mq(md) {
					font-size: em(12);
					margin-left: 0;
					background: none;
					width: 50px;
					height: 50px;
					padding: 0;
					a {
						height: 50px !important;
						width: 50px;
					}
				}
				a {
					display: block;
				}
				&.current-lang {
					background: var(--white);
					height: 50px;
					justify-content: center;
					width: 50px;
					@include mq(md) {
						background: none;
					}
					a {
						opacity: 1;
						color: var(--green01);
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						display: flex;
						@include mq(md) {
							width: 50px;
							height: 50px;
							color: var(--white);
							&:hover {
								color: var(--green01);
							}
						}
					}
				}
			}

			a {
				width: 50px;
				height: 50px;
				border-radius: 100px;
				transition: 0.2s;
				&:hover {
					background: var(--white);
					color: var(--green02);
					border: 3px solid var(--white);
					opacity: 1;
					font-weight: 700;
					transition: 0.2s;
				}
			}
		}

		.menu-item-has-children {
			&:hover {
				cursor: pointer;
				.sub-menu {
					display: flex;
				}
			}

			a {
				color: #b3b3b3;
				font-size: em(9);

				@include mq(md) {
					color: inherit;
					font-size: inherit;
				}
			}

			li a {
				color: #fff;
				font-size: inherit;
				text-align: center;
				@include mq(md) {
					opacity: 0.7;
				}
			}
		}
	}

	&__portal {
		font-weight: 700;
		border: 1px solid #186eff99;
		color: #fff;
		border-radius: 50px;
		display: flex;
		align-items: center;
		padding: 10px 45px;
		text-decoration: none;
		transition: 0.2s;

		margin: 0;
		font-size: em(14);
		background-image: linear-gradient(
			180deg,
			var(--blue04) 0%,
			var(--light-blue) 100%
		);

		@include mq(md) {
			margin: 15px 0;
			background-image: linear-gradient(
				180deg,
				transparent 0%,
				transparent 100%
			);
		}

		&:hover {
			background-image: linear-gradient(
				180deg,
				var(--blue04) 0%,
				var(--light-blue) 100%
			);
		}
	}
}

.menu-cabecalho-es-container .menu-item-has-children:hover .sub-menu {
	flex-direction: initial;
	@include mq(md) {
		flex-direction: column-reverse;
	}
}

@include mq(md) {
	.page-template-consumidor-es {
		.nav ul .sub-menu:before {
			background: var(--blue02);
		}
	}
}
