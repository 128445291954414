.videoModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.8);
  cursor: pointer;
  display: none;
  transition: all 0.15s ease-in;
  z-index: 112;

  .modal-content {
    position: relative;
    background: #fff;
    cursor: default;
    box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.5);
    width: 80%;
    background-color: lightcyan;
  }
}

.modal.is-visible {
  visibility: visible;
  opacity: 1;
}
