.page-single {
  padding-top: 80px;
  padding-bottom: 90px;

  @include mq(md) {
    padding-top: 0px;
  }
  .single-top {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    margin: 30px 0;
    @include mq(md) {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 30px;
      margin: 90px 0 60px 0;
    }
    img {
      margin: 0;
    }
    .top-img img {
      border-top-left-radius: 120px;
    }
    .breadcrumb {
      margin-bottom: 15px;
    }
    .category-list {
      margin-bottom: 15px;
      color: var(--green);
      text-transform: uppercase;
      font-size: 1rem;
    }
    .data {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    h1 {
      margin: 20px 0 30px 0;
    }
  }
  .content {
    max-width: 764px;
    margin: 0 auto;
    p {
      font-size: 1.6em;
      margin-bottom: 2.2rem;
      line-height: 160%;
      @include mq(sm) {
        font-size: 1.8em;
      }
    }
    strong {
      font-weight: 700;
      font-size: 2rem;
    }
  }
  h1 {
    // font-size: 2rem;
    text-transform: none;
    color: var(--green-dark);
    line-height: 1.2;
    margin: 0;
    margin-bottom: 15px;
    @include mq(sm) {
      // font-size: 4rem;
    }
  }
  h2 {
    // font-size: 18px;
    text-transform: none;
    color: var(--blue);
    line-height: 1.2;
    margin: 4rem 0 1rem 0;
    @include mq(sm) {
      // font-size: 22px;
    }
  }
  .date {
    text-transform: uppercase;
    color: var(--blue);
    margin: 20px 0;
  }
  .categories {
    margin: 20px 0 20px 0;
    @include mq(sm) {
      margin: 40px 0 30px 0;
    }
    a,
    a:visited {
      text-transform: lowercase;
      color: var(--blue);
      text-decoration: none;
    }
  }
  figure {
    margin: 50px 0;
    text-align: center;
    img {
      border-radius: 18px;
    }
  }

  ul,
  ol {
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
  ol {
    list-style-type: decimal;
  }
  .post-infos {
    font-weight: 700;
    color: #757575;
  }
  .video {
    background: none;
    padding: 0;
    margin: 50px 0;
    .container {
      max-width: unset;
      padding: 0;
    }
    img {
      border-radius: 18px;
    }
  }

  .data-evento {
    color: var(--green-light);
    font-size: 3.2rem;
  }

  .single-slider {
    padding: 30px 0;
    img {
      border-radius: 12px;
    }
    .container {
      padding: 0;
    }
  }

  .share-box {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    position: relative;
    right: unset;
    bottom: unset;
    border: 4px solid var(--green-light);
    h4 {
      margin: 0;
    }
    a {
      display: block;
      line-height: 1;
    }
  }
}

.post__nav {
  background-color: var(--blue);
  padding: 60px 0;
  margin-top: 80px;
  color: #fff;
  .post-title {
    font-size: 16px;
    font-weight: 600;
    @include mq(sm) {
      font-size: 22px;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    display: block;
  }
  .single-nav-links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    @include mq(sm) {
      flex-direction: row;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      img {
        align-self: flex-start;
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 50%;
        mask-image: url(../images/mask-singlepost-thumb.svg);
        mask-size: 100% 100%;
      }
    }
    & > * {
      flex: 0 1 50%;
    }
    .single-nav-navs {
      display: flex;
      align-items: center;
      gap: 10px;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  .single-nav-right {
    text-align: right;
    .single-nav-navs {
      justify-content: flex-end;
    }
  }
  .nav-previous {
    .meta-nav {
      display: block;
      padding: 10px 0 5px 30px;
      background: url(../images/arrow-left.svg) no-repeat top 4px left;
    }
  }
  .nav-next {
    text-align: right;
    .meta-nav {
      display: block;
      padding: 10px 30px 5px 0;
      background: url(../images/arrow-right.svg) no-repeat top 4px right;
    }
  }
}

.top-img {
  img {
    width: 100%;
    border-radius: 18px;
    margin: 30px 0 15px 0;
  }
}
.page-page {
  h1 {
    margin-bottom: 2rem;
  }
}
