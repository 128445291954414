:root {
	--green01: #019147;
	--green02: #007137;

	--blue01: #02739a;
	--blue02: #005d7e;

	--red01: #d34252;
	--red02: #c44250;

	--white: #ffffff;
	--black: #000000;
}
