.a11y {
	position: fixed;
	top: 200px;
	right: 0;
	// width: 50px;
	width: 75px;
	height: 70px;
	overflow: hidden;
	display: none;
	padding-bottom: 10px;
	z-index: 99;
	transition: width 0.5s, height 0s 0.5s;
	align-items: flex-start;
	justify-content: flex-start;
	color: var(--dark-blue);

	@include mq(md) {
		display: flex;
	}

	&.active {
		width: 260px;
		height: 290px;
		transition: width 0.5s, height 0s 0s;
	}

	&__wrapper {
		position: relative;
		z-index: 9;
		display: flex;
	}

	&__menu {
		width: 200px;
		background: #fff;
		border-radius: 15px 0 0 15px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 15px;
		position: relative;
		-webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);

		h2 {
			color: #1f492c;
			font-size: 16px;

			.has--high-contrast & {
				color: #000;
			}
		}

		img {
			width: auto;

			.has--high-contrast & {
				filter: grayscale(100%) contrast(150%);
			}
		}
	}

	.separator {
		width: 130px;
		text-align: center;
		hr {
			background: #1f492c;
		}
	}

	&__btn {
		margin-top: 10px;
		cursor: pointer;
		display: flex;
		height: 62px;
		align-items: center;
		padding-left: 15px;
		// margin-left: -60px;
		width: 75px;
		flex-wrap: wrap;
		background: transparent;
		border: 0;
		// background-repeat: no-repeat;
		.has--high-contrast & {
			filter: grayscale(100%) contrast(150%);
		}

		span {
			background: #1f492c;
			border-radius: 50px;
			width: 94px;
			color: #fff;
			text-transform: uppercase;
			font-size: 8px;
			display: flex;
			height: 30px;
			align-items: center;
			padding-left: 10px;
			margin-right: -25px;
			text-align: left;
		}

		&-btn {
			width: 62px;
			height: 62px;

			background-position: center;
			background-repeat: no-repeat;

			img {
				display: block;
			}
		}
	}

	&__toggles {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		grid-gap: 8px;
		width: 150px;
		color: #1f492c;
	}

	&__toggle {
		cursor: pointer;
		border: 0;
		width: 56px;
		height: 56px;
		border-radius: 15px;
		display: flex;
		align-items: flex-end;
		font-size: 9px;
		text-transform: uppercase;
		font-weight: 700;
		justify-content: center;
		padding: 8px;
		background-color: transparent;

		.has--high-contrast & {
			border: 1px solid #000;
			filter: grayscale(100%) contrast(150%);
		}

		&--aumentar {
			background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Area' width='24' height='24' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Ccircle id='Path' cx='8' cy='8' r='8' transform='translate(1.71 0.501)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line' x1='4.35' y1='4.35' transform='translate(15.36 14.151)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line-2' data-name='Line' y2='6' transform='translate(9.71 5.501)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line-3' data-name='Line' x2='6' transform='translate(6.71 8.501)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;
		}

		&--diminuir {
			background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Area' width='24' height='24' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Ccircle id='Path' cx='8' cy='8' r='8' transform='translate(1.71 0.501)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line' x1='4.35' y1='4.35' transform='translate(15.36 14.151)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line-2' data-name='Line' x2='6' transform='translate(6.71 8.501)' fill='none' stroke='%231F492C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;
		}

		&--contraste {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Grupo_95' data-name='Grupo 95' transform='translate(-1267 -452)'%3E%3Ccircle id='Elipse_7' data-name='Elipse 7' cx='9' cy='9' r='9' transform='translate(1267 452)' fill='%231F492C'/%3E%3Cpath id='Caminho_179' data-name='Caminho 179' d='M7,0A7,7,0,0,1,7,14Z' transform='translate(1269 454)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&__close {
		border: none;
		position: absolute;
		top: 15px;
		left: 11px;
		background-color: transparent;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.557' height='15.557' viewBox='0 0 15.557 15.557'%3E%3Cg id='Icon_' data-name='Icon ' transform='translate(5.657 -7.778) rotate(45)'%3E%3Cg id='Icon' transform='translate(-1 -1)'%3E%3Cline id='Line' x2='18' transform='translate(4.5 10.5)' fill='none' stroke='%234e3c54' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line-2' data-name='Line' x2='18' transform='translate(13.5 1.5) rotate(90)' fill='none' stroke='%234e3c54' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center;
		height: 20px;
		width: 20px;
		cursor: pointer;
	}
}

.has--high-contrast > * {
	filter: grayscale(100%);
}
