.aba-cookies {
  background: #102257;
  position: fixed;
  min-height: 90px;
  padding: 20px 0;
  display: none;
  z-index: 9999999999;
  border-radius: 30px;
  bottom: 0;
  left: 0;
  right: 0;

  &.show {
    display: block;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 30px;

    @include mq(md) {
      flex-direction: row;
    }
  }

  &__btn {
    text-transform: none;
    margin-top: 0 !important;
    white-space: nowrap;
    // background: transparent;
    // color: #fff;
    // border: 3px solid #fff;
    // border-radius: 10px;
    // line-height: 50px;
    // font-size: em(16);
    // padding: 0 35px;
    // white-space: nowrap;
    // margin-left: 30px;
    // text-transform: uppercase;
    // font-weight: 700;
    // cursor: pointer;

  }

  p {
    font-size: em(16);
    line-height: 22px;
    color: #fff
  }

  a {
    color: #fff;
  }
}
