.consumidor {
	&__faq {
		margin: 60px 0;
		h2,
		h3,
		p {
			color: var(--green01);
		}
		&-title {
			text-align: center;
		}
		&-perguntas {
			details {
				color: var(--green01);
				border: 2px solid var(--green01);
				transition: all 0.2s;
				border-radius: 25px;
				margin: 25px 0;
				box-shadow: 15px 15px 15px #00713710;
				&:last-of-type {
					margin-bottom: 0;
				}
				&[open] {
					summary {
						transition: font-size 0.2s ease-in-out;
						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}
			summary {
				padding: 30px 80px 30px 30px;
				list-style-type: none;
				position: relative;
				font-size: em(18);
				font-weight: 600;
				line-height: 145%;
				transition: font-size 0.2s ease-in-out;
				&:after {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath d='M9.24213 11.2677C9.39721 11.0963 9.60751 11 9.8268 11C10.0461 11 10.2564 11.0963 10.4115 11.2677L14.505 15.7932L18.5985 11.2677C18.7545 11.1011 18.9634 11.009 19.1802 11.0111C19.397 11.0132 19.6045 11.1093 19.7578 11.2788C19.9111 11.4483 19.9981 11.6776 20 11.9173C20.0019 12.1571 19.9185 12.388 19.7679 12.5604L15.0897 17.7323C14.9346 17.9037 14.7243 18 14.505 18C14.2857 18 14.0754 17.9037 13.9203 17.7323L9.24213 12.5604C9.08709 12.389 9 12.1565 9 11.9141C9 11.6716 9.08709 11.4391 9.24213 11.2677Z' fill='%23019147'/%3E%3C/svg%3E");
					color: var(--green02);
					background: var(--white);
					border: 2px solid var(--green01);
					transition: all 0.2s;
					cursor: pointer;
					border-radius: 50%;
					justify-content: center;
					width: 28px;
					height: 28px;
					font-weight: 700;
					display: flex;
					position: absolute;
					top: 15px;
					right: 15px;
				}
			}
			& .faq-content {
				padding: 0 30px 30px 30px;
				p {
					margin: 0;
				}
				.faq-fonte{
					font-size: em(15);
				}
			}
		}
	}
}

@include mq(md) {
	.consumidor {
		&__faq {
			margin: 90px 0;
			&-perguntas {
				summary {
					padding: 50px 220px 50px 85px;
					cursor: pointer;
				}
				.faq-content {
					padding: 0 85px 30px;
				}
				details {
					transition: all 0.2s;
					&[open] {
						transition: all 0.2s;
					}
				}
			}
			&-title {
				h3 {
					width: 50%;
					margin: 0 auto;
				}
			}
		}
	}
}
