.parceiro {
	&__depoimentos {
		padding: 60px 0;
		&-title {
			text-align: center;
			h2,
			h3 {
				color: var(--blue01);
			}
		}
		&-swiper {
			position: relative;
			margin-top: 30px;
			.swiper {
				&-slide {
					text-align: center;
					img {
						// filter: drop-shadow(
						// 	15px 15px 100px rgba(0, 0, 0, 0.25)
						// );
						z-index: -1;
						border-radius: 50%;
					}
					p {
						color: var(--blue01);
						&:first-of-type {
							margin-top: 0;
						}
						&:last-of-type {
							margin-bottom: 0;
						}
					}
					.parceiro__depoimentos-img {
						position: relative;
						margin: 0 auto 30px;
						width: 106px;
						height: 106px;
						&-pessoa {
							margin: 0 auto 30px;
							border-radius: 50%;
							border: 5px solid var(--blue01);
							width: 106px;
							height: 106px;
							display: flex;
							overflow: hidden !important;
							align-items: center;
							justify-content: center;
						}
						.folha {
							position: absolute;
							right: -5px;
							bottom: -5px;
							z-index: 1;
							border-radius: unset;
						}
					}
				}
				&-arrows {
					display: flex;
					justify-content: center;
					gap: 5px;
					margin-top: 30px;
					& > div {
						border: 1px solid var(--blue01);
						border-radius: 50%;
						width: 28px;
						height: 28px;
						transition: all 0.2s;
						position: unset;
						top: unset;
						bottom: -10%;
						margin: 0;
						transform: unset;
						&:after {
							transition: all 0.2s;
							font-size: 10px;
							font-weight: 700;
							color: var(--blue01);
						}
						&:hover {
							transition: all 0.2s;
							background: var(--blue01);
							&:after {
								transition: all 0.2s;
								color: var(--white);
							}
						}
						&.swiper-button-next {
							right: unset;
						}
						&.swiper-button-prev {
							left: unset;
						}
					}
				}
			}
		}
	}
}

@include mq(md) {
	.parceiro {
		&__depoimentos {
			&-title {
				width: 810px;
				margin: 0 auto;
			}
			&-swiper {
				width: 810px;
				margin: 30px auto 0;
			}
		}
	}
}
