@import "common/mixins";
@import "common/reset";
@import "components/header";
@import "components/nav";
@import "components/footer";
@import "components/modal";
@import "components/a11y";
@import "components/forms";
@import "components/swiper";
@import "components/hamburger";
@import "components/mobile-nav";
@import "components/aba-cookies";
@import "common/colors";
@import "common/scaffolding";
@import "common/slider";
@import "common/global";
@import "common/highcontrast";

@import "pages/heading";
@import "pages/content";

@import "components/templates/socialwall";
@import "components/templates/btnparceiro";
@import "components/templates/cookies";

@import "pages/parceiro/topo";
@import "pages/parceiro/comofunciona";
@import "pages/parceiro/impacto";
@import "pages/parceiro/efeitos";
@import "pages/parceiro/beneficios";
@import "pages/parceiro/futuro";
@import "pages/parceiro/depoimentos";
@import "pages/parceiro/produtos";
@import "pages/parceiro/ondeencontrar";
@import "pages/parceiro/calculadora";
@import "pages/parceiro/faq";

@import "pages/consumidor/topo";
@import "pages/consumidor/comofunciona";
@import "pages/consumidor/produtos";
@import "pages/consumidor/ondeencontrar";
@import "pages/consumidor/calculadora";
@import "pages/consumidor/faq";

@import "pages/produtos/topo";
@import "pages/produtos/lista";

@import "pages/ondeencontrar/topo";
@import "pages/ondeencontrar/lista";

@import "pages/post/heading";
@import "pages/post/content";
@import "pages/post/relacionados";

@import "pages/contato/topo";
@import "pages/contato/faq";

@import "pages/midia/topo";
@import "pages/midia/cards";
@import "pages/midia/interna";

@import "pages/home/home";

@import "pages/erro404/topo";

@import "pages/politica/topo";
@import "pages/politica/content";

@import "pages/conteudos/busca";
@import "pages/conteudos/recentes";
@import "pages/conteudos/populares";
@import "pages/conteudos/categorias";
@import "pages/conteudos/conteudos";
@import "pages/conteudos/download";

@import "pages/page-single";

@import "pages/404";

html {
	@include mq(sm) {
		color: inherit;
	}
	@include mq(md) {
		color: inherit;
	}
	@include mq(lg) {
		color: inherit;
	}
}
