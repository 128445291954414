.heading {
  background: var(--dark-blue);
  color: #fff;
  top: 0;
  border-radius: 0 0 50px 0;

  @include mq(md) {
    border-radius: 0 0 125px 0; 
  }

  // &__grid {
  //   display: grid;
  //   grid-template-columns: repeat(2,1fr);
  // }

  .page-template-dark-bg &,
  .page-template-contato &,
  .page-template-produto &,
  .page-template-conteudos &,
  .archive &,
  .blog &,
  .search & {
    border-radius: 0; 
  }

  &__text {
    width: 100%;
    min-height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;

    @include mq(md) {
      margin-top: 100px;
      text-align: left;
      width: 50%;
      min-height: 358px;
    }
  }

  &__image {
    width: 100%;
    position: relative;
    right: 0;
    top: 30px;
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
    height: 100%;

    @include mq(md) {
      width: 60%;
      position: absolute;
      margin: 0;
    }

    &:before{
    display: block;
    content: '';
    width: 100%;
    height: 50px;
    background: linear-gradient(180deg, var(--dark-blue) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;

    @include mq(md) {
      display: none;
    }
    }

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left:0;
      right: 0;
      bottom: 0;
      background-image: radial-gradient(60% 50% ellipse at 50% 50%,transparent 0,var(--dark-blue) 90%);
    
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  }

  
}