.btnparceiro {
	&-btn {
		position: fixed;
		right: 10px;
		bottom: 5%;
		z-index: 999;
		opacity: 0;
		visibility: hidden;
		transition: 0.2s;
		@include mq(md) {
			bottom: 8%;
		}
		&.active {
			opacity: 1;
			visibility: visible;
			transition: 0.2s;
		}
		a {
			font-size: 1.6em;
			background: var(--red01);
			border-radius: 100px;
			border: 2px solid var(--red01);
			text-align: center;
			padding: 0 30px;
			color: var(--white);
			text-decoration: none;
			transition: all 0.2s;
			display: block;
			width: fit-content;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.2s;
			filter: drop-shadow(15px 15px 100px rgba(0, 0, 0, 0.25));
			svg {
				margin-left: 10px;
				transition: 0.2s;
				path {
					transition: 0.2s;
				}
			}
			&:hover {
				background: var(--white);
				color: var(--red01);
				transition: 0.2s;
				svg,
				svg path {
					transition: 0.2s;
					fill: var(--red01);
				}
			}
		}
	}
}
