.parceiro {
	&__efeitos {
		padding: 60px 0;
		h2,
		h3,
		p {
			color: var(--green01);
		}
		&-efeitos {
			margin-top: 50px;
			.box-efeito {
				--pl: 32px;
				position: relative;
				margin-bottom: 30px;
				border: 1px solid var(--green01);
				border-radius: 20px;
				padding: 85px 20px 50px var(--pl);
				&:last-of-type {
					margin-bottom: 0;
				}
				img {
					border: 2px solid var(--green01);
					border-radius: 15px;
					padding: 12px;
					background: var(--white);
					position: absolute;
					top: -5%;
					left: calc(var(--pl));
				}
				p {
					&:first-of-type {
						margin-top: 0;
					}
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@include mq(md) {
	.parceiro {
		&__efeitos {
			padding: 100px 0;
			.wrapper {
				display: grid;
				grid-template-columns: 0.8fr 2fr;
				gap: 0 40px;
			}
			&-title {
				grid-column: 1;
				h3 {
					margin-bottom: 0;
				}
			}
			&-efeitos {
				grid-column: 2;
				margin-top: 0;
				display: flex;
				gap: 40px;
				.box-efeito {
					margin-bottom: 0;
					padding: 85px 32px 50px var(--pl);
				}
			}
		}
	}
}
