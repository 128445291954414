.conteudos-categorias {
  &__lista {
    background: #fff;
    border-radius: 50px;
    // width: 100%;
    padding: 45px;
    color: #00185B;
    flex-direction: column;

    ul {
      width: auto;
      padding-bottom: 0;
      flex-direction: column;

      @include mq(md) {
        // padding: 0 30px 30px;
        padding: 0;
        padding-bottom: 30px;
      }

      li {
      width: auto;
      flex: 0 0 auto;
      padding: 0;
      border: 0;
      @include mq(md) {
        // padding: 25px 30px;
        padding: 0;
        border: 0;
      }

      &:before {
        display: none !important;
      }
    }
    }
  }
}
