.cookies {
	opacity: 0;
	visibility: hidden;
	width: 90%;
	margin: 0 auto;
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	background: var(--white);
	border-radius: 20px;
	box-shadow: 0 0 20px 10px #00713730;
	z-index: 9999;
	transition: all 0.2s;
	display: none;

	&.active {
		display: block;
		opacity: 1;
		visibility: visible;
		transition: all 0.2s;
	}
	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		padding: 30px 20px 15px;
		p {
			color: var(--green01);
			text-align: center;
			font-size: 14px;
			a {
				text-decoration: underline;
				transition: all 0.2s;
				font-weight: 300;
				color: inherit;
				&:hover {
					font-weight: 700;
					transition: all 0.2s;
					cursor: pointer;
				}
			}
		}
		&-btn {
			button {
				background: var(--green01);
				color: var(--white);
				transition: all 0.2s;
				border: none;
				border: 1px solid var(--green01);
				font-size: 14px;
				width: 147px;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 100px;
				cursor: pointer;
				&:hover {
					background: var(--white);
					border: 1px solid var(--green01);
					color: var(--green01);
					transition: all 0.2s;

					svg {
						circle {
							stroke: var(--green01);
							transition: all 0.2s;
						}

						line {
							stroke: var(--green01);
							transition: all 0.2s;
						}
					}
				}

				svg {
					position: unset;
					circle,
					line {
						transition: all 0.2s;
					}
				}
			}
		}
	}

	@include mq(md) {
		width: 100%;
		max-width: 1180px;
		border-radius: 25px;
		margin: 0 auto;

		&__content {
			flex-direction: row;
			justify-content: space-between;
			padding: 20px;
			gap: 20px;
			p {
				font-size: 16px;
				text-align: center;
			}
		}
	}
}
