.content {
  max-width: 100%;
  background: var(--dark-blue);
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;

  li {
  padding-left: 23px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='14.621'%3E%3Cpath data-name='Icon feather-check' d='M18.121 2.121l-11 11-5-5' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E");
  background-position: left 9px;
  background-repeat: no-repeat;
  background-size: 16px 11px;
  line-height: 1.8;
  }

  &--white {
    background-image: linear-gradient(90deg, transparent 49.9%, #fff 50%);
    padding-bottom: 0px;

    .wrapper__large {
      padding: 0 15px;

      .wrapper__small {
        padding: 0 30px;
      }
    }
  }

  &__wrapper {
    width: 100%;
    padding: 40px 0;
    border-radius: 50px 0 0 50px; 
    // overflow: hidden;

    @include mq(md) {
      border-radius: 125px 0 0 125px; 
      padding: 90px 0;
      overflow: visible;
    }

    .content--dark & {
      color: #fff;

      a {
        color: inherit;
      }
    }

    .content--white & {
      background: #fff;
      color: var(--dark-grey);
      position: relative;
      z-index: 1;

    }

    .single &--thumb {
      padding-top: 100px;
      @include mq(md) {
        padding-top: 210px;
      }
    }
    
  }

  .wrapper {
    position: relative;
    z-index: 1;
  }
}