.footer {
	color: #fff;
	background: var(--green01);
	&-icons {
		height: 68px;
		background: var(--white) url(../../images/icons-footer-m.svg) no-repeat
			center 19px;
		@include mq(md) {
			background: var(--white) url(../../images/icons-footer.svg)
				no-repeat center 17px;
		}
	}
	a {
		color: #fff;
		text-decoration: none;
	}

	.wrapper__large {
		overflow: hidden;
		padding: 2em 30px;
	}

	.menu-footer-container {
		flex: 0 1 100%;
		margin-bottom: 65px;
		overflow: visible;
		width: 100vw;
		margin-left: -30px;

		@include mq(md) {
			width: auto;
			margin-left: 0px;
		}
	}

	&__flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3em;
		@include mq(md) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__logo {
		// width: 160px;
		margin: 0 auto;
		@include mq(md) {
			order: 2;
			margin: 0;
		}
	}

	a {
		color: #fff;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}

		&[href]:hover {
			text-decoration: underline;
		}
	}

	&__copyright {
		color: var(--white);
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		z-index: 1;

		@include mq(md) {
			flex-direction: row;
			align-items: flex-start;
			gap: 5px;
			order: 1;
		}

		p {
			font-size: em(14);
			margin: 3px 0;

			@include mq(md) {
			}
		}
	}

	&__politica {
		p {
			font-size: em(14);
			margin: 0;
		}

		@include mq(md) {
			order: 3;
		}
	}
}
