.conteudos {
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
    margin-top: 20px;

    @include mq(md) {
      grid-template-columns: 2fr 1fr;
    }
  }

  &__bg {
    width: calc(100% - 60px);
    height: 0;
    padding-bottom: calc(100% - 60px);
    border-radius: 30px;
    background-image: linear-gradient(180deg, var(--text-blue) 0%, #3343A1 100%);
    position: relative;
    text-align: center;
    margin: 0 30px;
    margin-bottom: -70px;
    box-shadow: 0px 10px 20px -10px rgba(24,110,255,0.7);
    position: relative;
    z-index: 1;

    h3, h4 {
      color: #fff !important;
    }
  }

  .swiper-slide {

    &:nth-child(2n) {
      .conteudos__bg {
        background-image: linear-gradient(180deg, var(--blue04) 0%, var(--light-blue) 100%);
      }
      
    }

    &:hover {
      .conteudos__ttl {
        opacity: 0;
        transition: .2s;
      }

      .conteudos__hover {
        opacity: 1;
        transition: .2s;
      }
    }
  }

  &__ttl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: .2s;

    h3 {
      margin-top: 30px;
    }
  }

  &__hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .2s;
  }

  &__list {
    border: 1px solid var(--blue04);
    border-radius: 32px;
    padding: 100px 30px 20px 30px;

    

    a {
      color: var(--text-blue);
      text-decoration: none;
    }

    li {
      margin-bottom: 20px;
      position: relative;

      &:first-child {
      &:before {
        content: '';
        height: 100%;
        width: 2px;
        background: #186EFF;
        left: -30px;
        position: absolute;
        display: block;
      }
    }
    }
  }

  .swiper-pagination {
    margin-top: 50px;
    position: relative;

    @include mq(md) {
      display: none;    
    }
  }
  
  

  &__footer {
    display: flex;
    justify-content: center;
  }
}