.midia {
	&__cards {
		margin: 110px 0;
		.alm-btn-wrap {
			.alm-load-more-btn {
				border: 1px solid var(--green01);
				color: var(--green01);
				border-radius: 100px;
				background: var(--white);
				transition: 0.2s;
				width: 260px;
				height: 60px;
				&.loading {
					background: var(--white);
					color: var(--green01);
					&:before {
						height: 58px;
						top: -6px;
						left: 40px;
						background-color: unset;
					}
				}
				&:hover {
					background: var(--green01);
					color: var(--white);
					transition: 0.2s;
				}
				&:disabled {
					background: var(--green01);
					color: var(--white);
				}
			}
		}
		&-grid {
			display: flex;
			flex-direction: column;
			gap: 50px;
			margin-bottom: 50px;
			&:last-of-type {
				margin-bottom: 0;
			}
			.midia-destaque {
				cursor: default;
				&:hover {
					.novidade-card {
						.content {
							border: 2px solid var(--green01);
							border-top: none;
							transition: 0.2s;
							@include mq(md) {
								border-top: 2px solid var(--green01);
								border-left: none;
							}
							.info {
								.autor {
									p {
										color: var(--green01);
										transition: 0.2s;
									}
									svg,
									svg path {
										fill: var(--green01);
										transition: 0.2s;
									}
								}
								.categoria {
								}
							}
							.text {
								h3,
								p {
									color: var(--green01);
									transition: 0.2s;
								}
								.leiamais {
									p {
										color: var(--green01);
										transition: 0.2s;
									}
									svg,
									svg path {
										fill: var(--green01);
										transition: 0.2s;
									}
									.data {
										color: var(--green01);
										transition: 0.2s;
									}
								}
							}
						}
					}
				}
				a {
					text-decoration: none;
				}
				.novidade-card {
					text-align: center;
					img {
						width: 350px;
						height: 350px;
						object-fit: cover;
						border-radius: 20px 20px 0px 0px;
					}
					.content {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						border: 2px solid #dae3d8;
						border-radius: 0 0 20px 20px;
						border-top: none;
						margin: 0 auto;
						width: 350px;
						height: 370px;
						color: #434343;
						padding: 30px;
						transition: 0.2s;
						.info {
							display: flex;
							justify-content: space-between;
							.categoria,
							.autor {
								display: flex;
								align-items: center;
								gap: 5px;
								p {
									font-size: 16px;
									margin: 0;
									color: #434343;
									transition: 0.2s;
								}
								svg,
								svg path {
									fill: #434343;
									transition: 0.2s;
								}
							}
							.categoria {
								a {
									display: flex;
									align-items: center;
									gap: 5px;
									font-size: 16px;
									color: var(--green01);
									border: 1px solid var(--green01);
									height: 30px;
									border-radius: 100px;
									padding: 0 10px;
									transition: 0.2s;
									@include mq(md) {
										height: 40px;
										padding: 0 20px;
									}
									svg,
									svg path {
										fill: var(--green01);
										transition: 0.2s;
									}
									&:hover {
										transition: 0.2s;
										background: var(--green01);
										color: var(--white);
										svg,
										svg path {
											transition: 0.2s;
											fill: var(--white);
										}
									}
								}
							}
						}
						.text {
							h3,
							p {
								color: #434343;
								text-align: left;
								transition: 0.2s;
							}
							h3 {
								font-size: 20px;
							}
							p {
								font-size: 16px;
							}
						}
						.leiamais {
							display: flex;
							align-items: center;
							gap: 10px;
							justify-content: space-between;
							.data {
								font-size: 14px;
								display: flex;
								align-items: center;
								gap: 5px;
								transition: 0.2s;
							}
							p {
								font-size: 14px;
								font-weight: 600;
								color: #434343;
								text-align: left;
								transition: 0.2s;
								margin: 0;
							}
							svg,
							svg path {
								fill: #434343;
								transition: 0.2s;
							}
							a {
								display: flex;
								align-items: center;
								gap: 10px;
								text-decoration: none;
								&:hover {
									svg {
										animation: seta 1s infinite;
									}
								}
							}
						}
					}
				}
			}
			.midia-noticia {
				cursor: default;
				&:hover {
					.novidade-card {
						.content {
							border: 2px solid var(--green01);
							border-top: none;
							transition: 0.2s;
							.info {
								position: relative;
								.categoria,
								.autor {
									p {
										color: var(--green01);
										transition: 0.2s;
									}
									svg,
									svg path {
										fill: var(--green01);
										transition: 0.2s;
									}
								}
							}
							.text {
								h3,
								p {
									color: var(--green01);
									transition: 0.2s;
								}
							}
							.leiamais {
								p {
									color: var(--green01);
									transition: 0.2s;
								}
								svg,
								svg path {
									fill: var(--green01);
									transition: 0.2s;
								}
								.data {
									color: var(--green01);
									transition: 0.2s;
								}
							}
						}
					}
				}
				a.card {
					text-decoration: none;
				}
				.novidade-card {
					text-align: center;
					img {
						height: 320px;
						object-fit: cover;
						border-radius: 20px 20px 0px 0px;
					}
					.content {
						display: flex;
						flex-direction: column;
						border: 2px solid #dae3d8;
						border-radius: 0 0 20px 20px;
						border-top: none;
						margin: 0 auto;
						min-height: 250px;
						color: #434343;
						padding: 20px 30px;
						transition: 0.2s;
						gap: 10px;
						height: 330px;
						justify-content: space-between;
						&-noticia {
							display: flex;
							flex-direction: column;
							gap: 20px;
						}
						.info {
							display: flex;
							justify-content: space-between;
							.categoria,
							.autor {
								display: flex;
								align-items: center;
								gap: 5px;
								p {
									font-size: 16px;
									margin: 0;
									color: #434343;
									transition: 0.2s;
								}
								svg,
								svg path {
									fill: #434343;
									transition: 0.2s;
								}
							}
							.categoria {
								a {
									display: flex;
									align-items: center;
									gap: 5px;
									font-size: 16px;
									color: var(--green01);
									border: 1px solid var(--green01);
									height: 30px;
									border-radius: 100px;
									padding: 0 10px;
									transition: 0.2s;
									text-decoration: none;
									svg,
									svg path {
										fill: var(--green01);
										transition: 0.2s;
									}
									&:hover {
										transition: 0.2s;
										background: var(--green01);
										color: var(--white);
										svg,
										svg path {
											transition: 0.2s;
											fill: var(--white);
										}
									}
								}
							}
						}
						.text {
							h3,
							p {
								color: #434343;
								text-align: left;
								transition: 0.2s;
							}
							h3 {
								font-size: 20px;
							}
							p {
								font-size: 16px;
							}
						}
						.leiamais {
							display: flex;
							align-items: center;
							gap: 10px;
							justify-content: space-between;
							.data {
								font-size: 14px;
								display: flex;
								align-items: center;
								gap: 5px;
								transition: 0.2s;
							}
							p {
								font-size: 14px;
								font-weight: 600;
								color: #434343;
								text-align: left;
								transition: 0.2s;
								margin: 0;
							}
							svg,
							svg path {
								fill: #434343;
								transition: 0.2s;
							}
							a {
								display: flex;
								align-items: center;
								gap: 10px;
								text-decoration: none;
								&:hover {
									svg {
										animation: seta 1s infinite;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@include mq(md) {
	.midia {
		&__cards {
			.alm-btn-wrap {
				.alm-load-more-btn {
					&:disabled {
						margin-top: 45px;
					}
				}
			}
			&-grid {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
				.midia-destaque {
					grid-column: 1/3;
					justify-self: center;
					width: 100%;
					.novidade-card {
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							height: 650px;
							width: 400px;
							border-radius: 20px 0px 0px 20px;
						}
						.content {
							border-radius: 0 20px 20px 0px;
							border: 2px solid #dae3d8;
							border-left: none;
							height: 650px;
							width: 100%;
							padding: 40px;
							.text {
								h3 {
									font-size: 30px;
								}
								p {
									font-size: 18px;
								}
								.leiamais {
									p {
										font-size: 14px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@keyframes seta {
	0%,
	100% {
		transform: translate(0, 0);
	}

	75% {
		transform: translate(3px, 0);
	}
}
