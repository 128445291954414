.parceiro {
	&__impacto {
		background: var(--blue01);
		padding-bottom: 60px;
		&-img {
			text-align: center;
			padding-top: 10px;
			margin-bottom: 30px;
			img {
				filter: drop-shadow(15px 15px 100px rgba(0, 0, 0, 0.25));
				width: 319px;
			}
		}
		&-impactos {
			flex-direction: column;
			display: flex;
			row-gap: 18px;
			margin-top: 30px;
			.boxes {
				display: flex;
				justify-content: center;
				gap: 18px;
				.box-impacto {
					flex: 1;
					height: 200px;
					border: 1px solid var(--white);
					border-radius: 20px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					span {
						font-size: 60px;
						color: var(--white);
					}
					p {
						padding: 0 15px;
						margin: 0;
					}
				}
			}
			.info {
				p {
					margin: 0;
					opacity: 0.7;
					text-align: center;
					small {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}
}

@include mq(md) {
	.parceiro {
		&__impacto {
			padding: 30px 0 90px;
			.wrapper {
				display: grid;
				grid-template-columns: 0.8fr 1fr;
				gap: 0 60px;
			}
			&-img {
				grid-column: 1;
				grid-row: 1/3;
				align-content: center;
				justify-self: flex-start;
				padding-top: 0;
				margin-bottom: 0;
				img {
					width: 444px;
				}
			}
			&-title {
				grid-column: 2;
			}
			&-impactos {
				grid-column: 2;
				display: flex;
				align-items: center;
				gap: 18px;
				margin: 30px 120px;
				.boxes {
					flex: 2;
					.box-impacto {
						span {
							font-size: 70px;
						}
						p {
							font-size: 16px;
							text-align: center;
						}
					}
				}
				.info {
					flex: 1;
				}
			}
		}
	}
}
