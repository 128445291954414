.parceiro {
	&__topo {
		background: var(--green01);
		padding-top: 90px;
		padding-bottom: 10px;
		@include mq(md) {
			padding-bottom: 0;
			padding-top: unset;
			margin-bottom: -20px;
		}
		&-header {
			height: 910px;
			overflow: hidden;
			@include mq(md) {
				height: 1050px;
				.wrapper {
					display: flex;
					align-items: center;
					padding-top: 90px;
					& > * {
						flex: 1;
						width: 50%;
					}
				}
			}
			.wrapper {
				position: relative;
				a {
					position: absolute;
					bottom: 5%;
					svg {
						cursor: pointer;
						border: 1px solid #ffffff70;
						border-radius: 12px;
						transition: 0.2s;
						path {
							transform: translateY(0);
							transition: 0.2s;
						}
						&:hover {
							background: var(--white);
							transition: 0.2s;
							path {
								transform: translateY(5px);
								transition: 0.2s;
								stroke: var(--green01);
							}
						}
					}
				}
			}
			&-text {
				z-index: 9;
				position: relative;
				p {
					font-size: em(20);
					@include mq(md) {
						font-size: em(30);
					}
				}
			}
			&-img {
				margin-top: 30px;
				position: relative;
				--tamanho-img: 430px;
				@include mq(md) {
					--tamanho-img: 778px;
					margin-top: -80px;
					margin-right: 30px;
				}
				.img-wrapper {
					width: var(--tamanho-img);
					height: var(--tamanho-img);
					overflow: hidden;
					border-radius: 50%;
					position: relative;
					margin-left: 50px;
					@include mq(md) {
					}
					img {
						&.banner {
							width: auto;
							transform: scale(1.5);
							margin-top: -45px;
							margin-left: -10px;
							@include mq(md) {
								transform: scale(1.55);
								margin-top: -120px;
								margin-left: -10px;
							}
						}
					}
				}
				img {
					&.selo {
						position: absolute;
						width: 140px;
						top: 0;
						left: 20px;
						@include mq(md) {
							width: 213px;
							bottom: 0;
							top: unset;
						}
					}
				}
				&:before {
					content: "";
					background: linear-gradient(
						137deg,
						rgba(55, 169, 88, 0) 14.99%,
						#37a958 76.26%
					);
					width: 670px;
					height: 670px;
					display: block;
					position: absolute;
					overflow: visible;
					top: calc((var(--tamanho-img) / 4) * -1);
					left: calc(((var(--tamanho-img) / 4) * -1) + 50px);
					border-radius: 50%;
					@include mq(md) {
						width: 1222px;
						height: 1222px;
					}
				}
			}
		}

		&-sobre {
			position: relative;
			margin-top: -180px;
			@include mq(md) {
				margin-top: -170px;
				.wrapper {
					display: flex;
					gap: 60px;
				}
				&-text {
					p {
						margin-bottom: 0;
					}
				}
				&-content {
					flex: 1;
				}
				&-vantagens {
					flex: 1.4;
				}
			}
			&-vantagens {
				@include mq(md) {
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-top: 130px;
				}
				.vantagem {
					position: relative;
					margin-bottom: 30px;
					--pl: 45px;
					@include mq(md) {
						--pl: 30px;
						width: 533px;
						margin-bottom: 20px;
						&.vantagem {
							&1 {
								align-self: flex-start;
							}
							&2 {
								align-self: center;
							}
							&3 {
								align-self: flex-end;
							}
						}
					}
					img {
						border: 2px solid var(--white);
						border-radius: 15px;
						padding: 12px;
						background: var(--green01);
						position: absolute;
						top: -10%;
						left: var(--pl);
					}
					p {
						border: 1px solid var(--white);
						border-radius: 20px;
						padding: 75px 40px 35px var(--pl);
						@include mq(md) {
							padding: 30px 30px 30px calc(var(--pl) + 75px);
						}
					}

					&:last-of-type {
						margin-bottom: 0;
						p {
							margin-bottom: 0;
						}
					}
				}
			}
			&-img {
				margin-bottom: 40px;
				@include mq(md) {
					transform: translateY(90px);
					margin-bottom: 0;
				}
			}
			img {
				filter: drop-shadow(15px 15px 100px rgba(0, 0, 0, 0.25));
			}
		}
	}
}
