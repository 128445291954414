.produtos {
	&__lista {
		margin: 50px 0;
		ul {
			li {
				margin-bottom: 70px;
				display: none;
				&.active {
					display: block;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
				.marca {
					&-info {
						display: flex;
						align-items: center;
						gap: 6px;
						margin-bottom: 40px;
						.marca-logo {
							width: 100px;
							height: 100px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 20px;
						}
						h3 {
							font-size: 20px;
							color: var(--green01);
						}
					}
				}
				.swiper {
					&-wrapper {
						padding: 0 20px;
					}
					&-slide {
						position: relative;
						.img-produto {
							border: 3px solid var(--green01);
							border-radius: 25px;
							height: 280px;
							width: 280px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.info-produto {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							left: 0;
							opacity: 0;
							transition: 0.2s;
							z-index: -1;
							border-radius: 25px;
							-js-display: flex;
							display: flex;
							width: 280px;
							height: 280px;
							justify-content: center;
							flex-direction: column;
							padding: 30px 20px;
							background: transparent;
							align-items: center;
							text-align: center;
							cursor: default;
							p {
								font-size: 14px;
								line-height: 22px;
								margin-top: 0;
								&:last-of-type {
									margin-bottom: 0;
								}
							}
							&.active {
								background: var(--green01);
								transition: 0.2s;
								opacity: 1;
								z-index: 1;
							}
						}
						.btn-saibamais {
							position: absolute;
							bottom: 35px;
							left: 0;
							right: 0;
							background: var(--green01);
							text-align: center;
							width: 168px;
							height: 50px;
							-js-display: flex;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 100px;
							border: 1px solid var(--green01);
							margin: 0 auto;
							p {
								color: var(--white);
								font-size: 16px;
								font-weight: 400;
							}
						}
					}
					&-arrows {
						display: none;
					}
				}
			}
		}
	}
}

@include mq(md) {
	.produtos {
		&__lista {
			margin: 50px 0 100px;
			ul {
				li {
					.wrapper-carrossel {
						margin: 0 auto;
						max-width: rem(1320);
						width: 100%;
						padding: 0 15px;
						position: relative;
					}
					.swiper {
						&-wrapper {
							padding: 0;
						}
						&-slide {
							display: flex;
							justify-content: center;
							.info-produto {
								margin: 0 auto;
							}
						}
						&-arrows {
							display: flex;
							.swiper-button {
								&-prev,
								&-next {
									border: 1px solid var(--blue01);
									border-radius: 50%;
									width: 28px;
									height: 28px;
									transition: all 0.2s;
									position: absolute;
									margin: 0;
									&:after {
										transition: all 0.2s;
										font-size: 10px;
										font-weight: 700;
										color: var(--blue01);
										content: "";
									}
									svg {
										transition: 0.2s;
										stroke-width: 1px;
										stroke: var(--green01);
										path {
											transition: 0.2s;
										}
									}
									&:hover {
										svg {
											stroke: var(--green01);
											stroke-width: 1px;
											transition: 0.2s;
											path {
												fill: var(--white);
												transition: 0.2s;
											}
										}
									}
								}
								&-prev {
									left: -1%;
								}
								&-next {
									right: -1%;
								}
							}
						}
					}
				}
			}
		}
	}
}
