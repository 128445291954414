.parceiro {
	&__futuro {
		background: var(--green01) url("../../images/img-muda.jpg") no-repeat
			center right 25%;
		padding: 50px 0;
		background-size: cover;
		@include mq(md) {
			padding: 100px 0;
			background-position-x: right;
		}
		&-text {
			width: 60%;
			@include mq(md) {
				width: 48%;
			}
			p {
				margin: 0;
				font-size: 20px;
				@include mq(md) {
					font-size: 40px;
				}
			}
		}
		&-btn {
			margin-top: 20px;
			a {
				font-size: 1.6em;
				background: var(--white);
				border-radius: 100px;
				border: 2px solid var(--white);
				text-align: center;
				padding: 17px 30px;
				color: var(--green01);
				text-decoration: none;
				transition: all 0.2s;
				display: block;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				&:hover {
					background: transparent;
					transition: all 0.2s;
					color: var(--white);
				}
			}
		}
	}
}
