%resetbtn {
	@include appearance(none);
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: transparent;
	color: inherit;
}

:root {
	--containerSize: #{rem(1120)};
	--navSize: #{rem(1044)};
	--gutter: #{rem(15)};
	--gutter2: #{rem(30)};

	box-sizing: border-box;

	&:before,
	&:after {
		box-sizing: border-box;
	}

	* {
		box-sizing: inherit;

		&:before,
		&:after {
			box-sizing: inherit;
		}
	}
}

$font-stack: "DM Sans", system-ui;

html,
body {
	font-size: 10px;
	font-family: $font-stack;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}

.container-fluid {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
}

main {
	flex-grow: 1;
	font-family: $font-stack;
}

.wrapper {
	flex-grow: 1;
	width: 100%;
	max-width: rem(1220);
	margin: 0 auto;
	padding: 0 20px;

	@include mq(md) {
		padding: 0 15px;
	}
}

.wrapper__full {
	flex-grow: 1;
	width: 100%;
	margin: 0 auto;
	padding: 0 30px;

	@include mq(md) {
		padding: 0 50px;
	}
}

.wrapper__small {
	flex-grow: 1;
	width: 100%;
	max-width: rem(980);
	margin: 0 auto;
	padding: 0 30px;

	@include mq(md) {
		padding: 0 15px;
	}
}

.wrapper__post {
	flex-grow: 1;
	width: 100%;
	max-width: rem(740);
	margin: 0 auto;
	padding: 0;

	@include mq(md) {
		padding: 0 15px;
	}
}

.wrapper__large {
	flex-grow: 1;
	width: 100%;
	max-width: rem(1230);
	margin: 0 auto;
	padding: 0 30px;

	@include mq(md) {
		padding: 0 15px;
	}

	.wrapper__small {
		padding: 0;

		@include mq(md) {
			padding: 0 15px;
		}
	}
}

.padded {
	padding-right: var(--gutter);
	padding-left: var(--gutter);
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
}

.has--text {
	strong {
		font-weight: 700;
	}

	> * {
		margin: 0;

		+ * {
			margin-top: 1em;
		}
	}
}

[hidden] {
	display: none !important;
}

.reset__btn {
	appearance: none;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: 0 0;
	color: inherit;
}

:focus {
	outline: var(--text-blue) dotted 1px;
}
body.has--modal {
	overflow: hidden;
}

/*aqueles blocos que são mais largos que o container*/
.block-full {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	overflow: hidden;
	// margin-bottom: 50px;

	@include mq(md) {
		margin-left: calc(-50vw - 9px);
	}
}

.wp-block-embed {
	margin: 2em 0;
}

.wp-block-embed__wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
	height: 0;
	overflow: hidden;
	margin-bottom: 1em;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		border-radius: 30px;
	}
}

.wp-block-image {
	img {
		border-radius: 30px;
	}
}
