.page-404 {
  padding-top: 60px;
  padding-bottom: 90px;
  text-align: center;
  overflow: hidden;
  position: relative;

  h1 {
    font-size: 15rem;
    text-transform: none;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 15px;
  }

  .btn {
    display: table;
    margin: 0 auto;
  }

  &__lottie {
    width: 370px;
    height: 370px;
    right: -250px;
    opacity: .1;
    position: absolute;
    bottom: auto;
    top: 50px;

    @include mq(md) {
      width: 670px;
    height: 670px;
    right: -215px;
    bottom: 0;
    top: auto;
    }
  }
}
