.parceiro {
	&__beneficios {
		background: var(--red01);
		padding: 60px 0;
		&-title {
			.info {
				display: none;
			}
		}
		&-beneficios {
			flex-direction: column;
			display: flex;
			row-gap: 18px;
			margin-top: 30px;
			.boxes {
				display: flex;
				justify-content: center;
				flex-direction: column;
				gap: 18px;
				.box-beneficios {
					border: 1px solid var(--white);
					border-radius: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 20px 0 20px 20px;
					span {
						font-size: 60px;
						color: var(--white);
					}
					p {
						padding: 0 15px;
						margin: 0;
					}
				}
			}
			.info {
				width: 80%;
				display: block;
				p {
					margin: 0;
					opacity: 0.7;
					small {
						font-size: 14px;
					}
				}
			}
		}
	}
}

@include mq(md) {
	.parceiro {
		&__beneficios {
			.wrapper {
				display: flex;
				flex-direction: row-reverse;
				gap: 100px;
				align-items: center;
			}
			&-title {
				flex: 1;
				.info {
					display: block;
					width: 80%;
					p {
						margin: 0;
						opacity: 0.7;
						small {
							font-size: 14px;
						}
					}
				}
			}
			&-beneficios {
				flex: 1;
				margin-top: 0;
				.boxes {
					.box-beneficios {
						width: 425px;
						&1 {
							align-self: flex-start;
						}
						&2 {
							align-self: center;
						}
						&3 {
							align-self: flex-end;
						}
					}
				}
				.info {
					display: none;
				}
			}
		}
	}
}

.page-template-parceiro-es {
	.parceiro__beneficios-beneficios .boxes .box-beneficios span {
		line-height: 50px;
		transform: translate(0px, -20px);
		span {
			font-size: 16px;
			line-height: 20px;
		}
	}
}
