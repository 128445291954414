.consumidor {
	&__calculadora {
		display: none;
		background: var(--blue01);
		padding: 0 0 60px 0;
		&-btn {
			margin-top: 25px;
			a {
				font-size: em(16);
				background: var(--white);
				border-radius: 100px;
				border: 2px solid var(--white);
				text-align: center;
				padding: 17px 30px;
				color: var(--blue01);
				text-decoration: none;
				transition: all 0.2s;
				display: block;
				width: fit-content;
				cursor: pointer;
				span {
					display: inline-block;
					transition: all 0.2s;
				}
				&:hover {
					background: transparent;
					transition: all 0.2s;
					color: var(--white);
					span {
						// transform: translate(10px, 10px);
						transition: all 0.2s;
						animation: seta 1s infinite;
					}
				}
			}
		}
		&-img {
			text-align: center;
			padding: 30px 0;
			img {
				filter: drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.25));
				width: 80%;
			}
		}

		@keyframes seta {
			0%,
			100% {
				transform: translate(0, 0);
			}

			75% {
				transform: translate(3px, -3px);
			}
		}
	}
}

@include mq(md) {
	.consumidor {
		&__calculadora {
			padding: 60px 0 80px;
			.wrapper {
				display: grid;
				grid-template-columns: 0.8fr 1fr;
				gap: 20px 0px;
				width: 980px;
				position: relative;
			}
			&-title {
				grid-column: 1;
				grid-row: 1;
			}
			&-btn {
				grid-column: 1;
				grid-row: 2;
				margin-top: 0;
			}
			&-img {
				margin-bottom: 0;
				position: absolute;
				right: 0;
				transform: translate(-100px, 0);
				img {
					width: unset;
				}
			}
		}
	}
}
