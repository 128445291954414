.midia {
	.wrapper {
		height: 100%;
	}
	.bg-gradient {
		height: 85px;
	}
	&__topo {
		background: var(--green01);
		padding-top: 90px;
		padding-bottom: 10px;
		&-title {
			h1 {
				font-size: em(14);
				font-weight: 300;
				line-height: em(12);
				text-transform: uppercase;
				color: var(--white);
				margin-bottom: 5px;
				margin-top: -90px;
				padding-top: 90px;

				strong {
					font-weight: 700;
				}

				@include mq(md) {
					font-size: em(16);
				}
			}
			h2 {
				font-size: em(32);
				font-weight: 300;
				margin: 0 0 10px 0;
				line-height: 125%;
				color: var(--white);
				padding-top: 0;
				text-transform: unset;
				strong {
					font-weight: 700;
				}

				@include mq(md) {
					font-size: em(50);
				}
			}
		}
		&-img {
			margin-bottom: 50px;
			text-align: center;
			img {
				filter: drop-shadow(15px 15px 100px rgba(0, 0, 0, 0.25));
				max-width: 333px;
				width: 100%;
			}
		}
		&-filtros {
			display: flex;
			align-items: center;
			height: 100%;
			gap: 12px;
			overflow: scroll visible;
			scrollbar-width: none;
		}
		&-filtro {
			a {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 8px;
				border: 1.5px solid var(--white);
				opacity: 0.5;
				border-radius: 100px;
				color: var(--white);
				padding: 0 12px;
				font-size: 14px;
				transition: all 0.2s;
				width: max-content;
				text-decoration: none;
				height: 40px;
				&:hover,
				&.active {
					opacity: 1;
					transition: all 0.2s;
				}
			}
		}
	}
}

@include mq(md) {
	.midia {
		&__topo {
			height: 421px;
			.wrapper {
				display: flex;
				align-items: center;
			}
			&-title {
				order: 1;
				flex: 3;
			}
			&-img {
				transform: translateY(80px);
				margin-bottom: 0;
				order: 2;
				flex: 2;
				img {
					max-width: 444px;
				}
			}
		}
	}
}

.single-post {
	li.current_page_parent {
		&:before {
			content: "";
			width: 0;
			height: 3px;
			left: 50%;
			transition: all 0.2s ease-in-out;
			position: absolute;
			top: 0;
			background: var(--white);
		}
		&:hover:before {
			width: 30px;
			left: calc(50% - 15px);
		}
	}
}
