.post-heading {
  background: var(--dark-blue);
  color: #fff;
  top: 0;
  border-radius: 0 0 50px 0;
  padding-top: 100px;
  overflow: visible;

  @include mq(md) {
    border-radius: 0 0 125px 0; 
    padding-top: 150px;
  }

  &__text {
    width: 100%;
    min-height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    h1 {
      max-width: 700px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    h4 {
      font-size: em(16);
      // color: var(--blue04);
      color: var(--light-blue);
      margin-bottom: 10px;
    }

    figure {
      margin: 0 auto;
      // border-radius: 30px;
      
      position: relative;
      z-index: 2;
      margin: 0 auto -100px;
      min-height: 150px;
      object-fit: contain;

      @include mq(md) {
        margin-bottom: -150px;
      }

      img {
        border-radius: 15px;

        @include mq(md) {
          border-radius: 30px;
        }
      }
    }
  }


}

.single .wp-post-image {
  
}