.post-content {
  &__text {
    ul {
      list-style: disc;
      margin-left: 15px;
    }
  }
  

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;

    @include mq(md) {
      grid-template-columns: 350px 1fr;
    }

    h2 {
      &:before {
        content: '';
        display: block;
        height: 100px;
        margin-top: -100px;
      }

    }
  }

  &__indice {
    display: none;

    @include mq(md) {
      display: block;
      // top: 150px;
      width: 350px;
    }

    &.sticky {
      position: fixed;
    }

    a {
      color: var(--dark-blue);
      text-decoration: none;
      font-size: em(20);
      margin-bottom: 15px;
      display: block;

      &:hover {
        color: var(--blue04);
      }
    }

    h4 {
      font-size: em(16);
      color: var(--dark-blue);
      border-bottom: 1px solid var(--blue04);
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
  }

  &__footer {
    border-top: 1px solid var(--blue04);
    padding-top: 30px;
    margin-top: 30px;

    h3 {
      text-align: center;

      @include mq(md) {
        text-align: left;
      }
    }

    ul {
      display: flex;
      border: 0;
      padding: 10px;

      @include mq(md) {
        border: 1px solid var(--dark-blue);
        border-radius: 50px;
      }

      li {
        border-right: 1px solid #00185B20;
        flex-grow: 1;
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0 10px;

        &:last-child {
          border-right: 0;
        }
      }
      
    }

    a, button {
      text-decoration: none;
      align-items: center;
      display: flex;
      background: transparent;
      border: 0;
      color: var(--dark-blue);
      grid-gap: 5px;
      justify-content: center;
      font-size: 16px;
      font-family: inherit;
      cursor: pointer;
      white-space: nowrap;
    }

    .bt-copy {
        &:before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.6' height='17.6' viewBox='0 0 17.6 17.6'%3E%3Cg id='Icon' transform='translate(0.8 0.8)'%3E%3Cg id='Area' fill='%23186eff' stroke='%23fd49c6' stroke-width='1' opacity='0'%3E%3Crect width='16' height='16' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' fill='none'/%3E%3C/g%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.289 2.499)'%3E%3Crect id='b6449f17-a9c3-4957-8a2b-a71bafdf1016' width='10.4' height='10.4' rx='2' transform='translate(4.311 3.101)' fill='none' stroke='%23186eff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3Cpath id='_9192015f-ca14-436e-9ac1-93b4450c1c5b' data-name='9192015f-ca14-436e-9ac1-93b4450c1c5b' d='M4,12H3.2a1.6,1.6,0,0,1-1.6-1.6V3.2A1.6,1.6,0,0,1,3.2,1.6h7.2A1.6,1.6,0,0,1,12,3.2V4' transform='translate(-2.889 -4.099)' fill='none' stroke='%23186eff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.6'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: flex;
          align-items: center;
        }

        &.bt-copiado {
          &:before {
            content: '✔';
            background: none;
            color: var(--blue04);
          }
        }
      }
  }

  &__scroll {
    max-width: calc(100vw - 15px);
    overflow-x: scroll;
    margin-left: -30px;
    padding: 0 30px;
    margin-right: -30px;
    margin-top: 20px;
    border-top: 1px solid var(--light-blue);
    border-bottom: 1px solid var(--light-blue);

    @include mq(md) {
      margin: 0;
      max-width: auto;
      overflow: visible;
      padding: 0;
      margin-top: 20px;
      border: 0;
    }
  }

}
