.conteudos-busca {
  position: relative;
  z-index: 1;
  width: 100%;

  @include mq(md) {
    width: 60%;
  }
  
  .searchandfilter ul {
    width: 100%;
    border: 1px solid #186EFF50;
    border-radius: 50px;
    padding: 5px;
    background: linear-gradient(225deg, rgba(7,29,86,.3) 0%, rgba(52,68,163,.3) 100%);
    display: grid;
    grid-template-columns: auto 50px;

    @include mq(md) {
      grid-template-columns: auto 130px;
      padding: 12px;
    }
  }

  input {
    padding: 10px;
    background: none;
    margin-top: 2px;
    border: 0;
    color: #fff;
    opacity: 1;

    @include mq(md) {
      margin-top: 0px;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #fff;
      opacity: .5;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #fff;
      opacity: .5;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #fff;
      opacity: .5;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #fff;
      opacity: .5;
    }
  }

  input[type="submit"] {
    border-radius: 50px;
    color: #fff;
    width: 50px;
    margin: 0;
    cursor: pointer;
    height: 50px;
    border: 0;
    font-size: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.609' height='13.609' viewBox='0 0 13.609 13.609'%3E%3Cg id='Icon' transform='translate(0.667 0.667)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Ccircle id='f6828338-2f9e-4afb-a5fe-5f8bb55362c8' cx='5.333' cy='5.333' r='5.333' transform='translate(-1.29 -2.499)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.333'/%3E%3Cline id='_577bb84a-3c84-4383-a9a4-cdba8c4b1ca0' data-name='577bb84a-3c84-4383-a9a4-cdba8c4b1ca0' x1='2.9' y1='2.9' transform='translate(7.81 6.601)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.333'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
    linear-gradient(180deg, var(--blue04) 0%, var(--light-blue) 100%);
    background-position: center;
    background-repeat: no-repeat;

    @include mq(md) {
      font-size: em(10);
      width: 130px;
      height: 100%;
      background: none;
      background-image: linear-gradient(180deg, var(--blue04) 0%, var(--light-blue) 100%);
    }
  }

  &__filtros {
    display: flex;
    grid-gap: 15px;
    margin-top: 30px;

    width: calc(100vw - 30px);
    overflow: scroll;
    padding-bottom: 20px;
    padding-right: 30px;

    @include mq(md) {
      padding: 0px;
      width: auto;
    overflow: auto;
    }
  }

  &__btn {
    border: 1px solid #186EFF50;
    border-radius: 50px;
    color: #fff;
    text-decoration: none;
    padding: 10px 35px;
    font-size: em(14);
    display: flex;
    align-items: center;
    grid-gap: 10px;
    justify-content: center;
  }
}