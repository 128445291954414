.contato {
	&__topo {
		padding-top: 90px;
		padding-bottom: 10px;
		background: var(--green01);
		&-img {
			margin-bottom: 50px;
			text-align: center;
			img {
				filter: drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.25));
				max-width: 310px;
				width: 100%;
			}
		}
		&-title {
			h1 {
				font-size: em(14);
				font-weight: 300;
				line-height: em(12);
				text-transform: uppercase;
				color: var(--white);
				margin-bottom: 5px;
				margin-top: -90px;
				padding-top: 90px;

				strong {
					font-weight: 700;
				}

				@include mq(md) {
					font-size: em(16);
				}
			}
			h2 {
				font-size: em(32);
				font-weight: 300;
				margin: 0 0 10px 0;
				line-height: 125%;
				color: var(--white);
				padding-top: 0;
				text-transform: unset;
				strong {
					font-weight: 700;
				}

				@include mq(md) {
					font-size: em(50);
				}
			}
		}
		&-form {
			&-inputs {
				display: flex;
				flex-direction: column;
				position: relative;
				column-gap: 16px;
				@include mq(md) {
					flex-direction: row;
				}
				&.form-floating {
					.wpcf7-form-control-wrap {
						&:has(> .form-control:focus),
						&:has(> .form-control:not(:placeholder-shown)) {
							& ~ label {
								font-size: 14px;
								opacity: 0.7;
							}
						}
					}
				}
			}
			&-input,
			&-textarea {
				position: relative;
				flex: 1;
				.wpcf7-not-valid-tip {
					color: white;
					font-size: 1em;
					font-weight: normal;
					display: block;
					background: var(--red01);
					width: fit-content;
					padding: 0 10px;
				}
				select {
					border: 1px solid var(--white);
					border-radius: 20px;
					height: 70px;
					font-size: 20px;
					color: var(--white);
					padding: 15px 20px 0;
					font-weight: 700;
					transition: all 0.2s;
					margin: 8px 0;
					background: var(--green01);
					option {
						background: var(--green01);
						color: var(--white);
						font-size: 16px;
						border-radius: 50px;
						transition: all 0.2s;
					}
				}
				input,
				textarea {
					background: var(--green01);
					border: 1px solid var(--white);
					border-radius: 20px;
					padding: 0 20px;
					color: var(--white);
					font-weight: 700;
					margin: 8px 0;
					width: 100%;
					transition: all 0.2s;
					padding-top: 15px;
					font-size: 20px;
				}
				input {
					height: 70px;
					font-size: 20px;
					&::placeholder {
						color: var(--green01);
					}
				}
				textarea {
					padding-top: 30px;
					height: 150px;
					&::placeholder {
						color: var(--green01);
					}
				}
				label {
					position: absolute;
					color: var(--white) !important;
					font-weight: 700;
					margin: 8px 0;
					top: 5px;
					left: 21px;
					transition: all 0.2s;
					font-size: 16px;
					padding-left: 0;
					&::after {
						background-color: transparent !important;
					}
				}
			}
			&-enviar {
				display: flex;
				flex-direction: column;
				@include mq(md) {
					flex-direction: row;
					justify-content: space-between;
				}

				label a.modal-termos-open {
					&:hover {
						text-decoration: underline;
					}
				}
			}

			&-checkbox {
				margin: 8px 0 20px 0;
				display: flex;
				align-items: center;
				input {
					width: 20px;
					height: 20px;
					accent-color: var(--green01);
					cursor: pointer;
				}
				@include mq(md) {
					margin: 0;
				}
				label {
					display: block;
					position: relative;
					padding-left: 0;
					cursor: pointer;
					font-size: 22px;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					font-size: 14px;
					color: var(--white);

					a {
						text-decoration: underline;
						color: var(--white);
					}

					@include mq(md) {
						font-size: 16px;
						padding-left: 0;
					}

					input {
						cursor: pointer;
					}
				}

				span:after {
					content: "";
					position: absolute;
					display: none;
				}
			}

			&-btn {
				display: flex;
				input {
					margin: 8px 0;
					background: var(--white);
					font-size: 18px;
					color: var(--green01);
					border: 2px solid var(--white);
					transition: 0.2s;
					&:hover {
						background: transparent;
						transition: 0.2s;
						color: var(--white);
					}
				}
			}
		}
	}
}

@include mq(md) {
	.contato {
		&__topo {
			padding-top: 150px;
			.wrapper {
				display: flex;
				gap: 100px;
			}
			&-img {
				margin-bottom: 0;
				order: 2;
				flex: 2;
				img {
					max-width: 444px;
				}
			}
			&-content {
				order: 1;
				flex: 3;
			}
			&-title {
				p {
					max-width: 500px;
				}
			}
		}
		&__faq {
			.wrapper {
				display: flex;
				gap: 100px;
			}
			&-img {
				margin-bottom: 0;
				flex: 2;
				img {
					max-width: 444px;
				}
			}
			&-content {
				flex: 3;
			}
			&-title {
				text-align: left;
			}
		}
	}
}
