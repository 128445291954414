.has--high-contrast {
	img {
		filter: grayscale(100%) contrast(150%);
	}

	video {
		filter: grayscale(100%) contrast(120%);
	}
}

.home.has--high-contrast {
	background-color: #e7e7e7;
	&.parceiro,
	&.consumidor {
		background-color: #e7e7e7;
	}
	.main-header {
		background: transparent;
	}
}
