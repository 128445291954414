.single {
	.content {
		&-post {
			margin-bottom: 60px;
			padding-top: 120px;
			&-intro {
				h1 {
					font-size: 32px;
					text-align: center;
					line-height: 45px;
					color: var(--green01);
				}
				h2 {
					font-size: 18px;
					text-align: center;
					line-height: 30px;
					font-weight: 400;
					color: var(--green01);
					text-transform: initial;
					margin: unset;
					padding: unset;
				}
				p {
					color: #727272;
					font-size: 16px;
					line-height: 26px;
					text-align: center;
				}
			}
			&-thumb {
				text-align: center;
				margin-bottom: 20px;
				img {
					border-radius: 30px;
				}
			}
			&-text {
				margin-bottom: 60px;
				blockquote {
					border-left: 5px solid #019147;
					padding-left: 25px;
					margin-left: 0;
				}
				figure {
					margin-bottom: 2em;
				}
				p {
					color: var(--black);
					a {
						color: var(--green01);
					}
				}
				h2 {
					font-size: 30px;
					font-weight: 700;
					text-transform: initial;
					margin-top: unset;
					padding-top: unset;
					color: var(--black);
					margin-bottom: 1em;
					margin-top: 1.5em;
				}
			}
			&-outro {
				&-categories {
					margin-bottom: 60px;
					display: flex;
					justify-content: space-evenly;
					a {
						font-size: 16px;
						background: #01914730;
						font-weight: 400;
						color: var(--green01);
						text-decoration: none;
						border-radius: 100px;
						padding: 8px 13px;
						border: 1px solid #01914730;
						transition: 0.2s;
						&:hover {
							background: var(--green01);
							color: var(--white);
							transition: 0.2s;
						}
					}
				}
				&-share {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
					margin-top: 60px;
					p {
						color: var(--green01);
					}
					.icons {
						display: flex;
						gap: 10px;
						a {
							svg {
								transition: 0.2s;
								rect,
								path {
									transition: 0.2s;
								}
							}
							&:hover {
								svg {
									fill: var(--green01);
									transition: 0.2s;
									path {
										fill: var(--white);
										transition: 0.2s;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.posts__carousel {
		padding-bottom: 100px;
		h3 {
			font-size: 50px;
			color: var(--green01);
			text-align: center;
			margin-bottom: 45px;
		}
		&-swiper {
			position: relative;
			.swiper-pagination {
				bottom: -35px;
				&-bullet-active {
					background: var(--green01);
				}
			}
		}
		.midia-noticia {
			cursor: default;
			&:hover {
				.novidade-card {
					.content {
						border: 2px solid var(--green01);
						border-top: none;
						transition: 0.2s;
						.info {
							position: relative;
							.categoria,
							.autor {
								p {
									color: var(--green01);
									transition: 0.2s;
								}
								svg,
								svg path {
									fill: var(--green01);
									transition: 0.2s;
								}
							}
						}
						.text {
							h3,
							p {
								color: var(--green01);
								transition: 0.2s;
							}
						}
						.leiamais {
							p {
								color: var(--green01);
								transition: 0.2s;
							}
							svg,
							svg path {
								fill: var(--green01);
								transition: 0.2s;
							}
							.data {
								color: var(--green01);
								transition: 0.2s;
							}
						}
					}
				}
			}
			a.card {
				text-decoration: none;
			}
			.novidade-card {
				text-align: center;
				img {
					height: 320px;
					object-fit: cover;
					border-radius: 20px 20px 0px 0px;
				}
				.content {
					display: flex;
					flex-direction: column;
					border: 2px solid #dae3d8;
					border-radius: 0 0 20px 20px;
					border-top: none;
					margin: 0 auto;
					min-height: 250px;
					color: #434343;
					padding: 20px 30px;
					transition: 0.2s;
					gap: 10px;
					height: 380px;
					justify-content: space-between;
					&-noticia {
						display: flex;
						flex-direction: column;
						gap: 20px;
					}
					.info {
						display: flex;
						justify-content: space-between;
						.categoria,
						.autor {
							display: flex;
							align-items: center;
							gap: 5px;
							p {
								font-size: 16px;
								margin: 0;
								color: #434343;
								transition: 0.2s;
							}
							svg,
							svg path {
								fill: #434343;
								transition: 0.2s;
							}
						}
						.categoria {
							a {
								display: flex;
								align-items: center;
								gap: 5px;
								font-size: 16px;
								color: var(--green01);
								border: 1px solid var(--green01);
								height: 30px;
								border-radius: 100px;
								padding: 0 10px;
								transition: 0.2s;
								text-decoration: none;
								svg,
								svg path {
									fill: var(--green01);
									transition: 0.2s;
								}
								&:hover {
									transition: 0.2s;
									background: var(--green01);
									color: var(--white);
									svg,
									svg path {
										transition: 0.2s;
										fill: var(--white);
									}
								}
							}
						}
					}
					.text {
						h3,
						p {
							color: #434343;
							text-align: left;
							transition: 0.2s;
						}
						h3 {
							font-size: 20px;
							margin-bottom: 10px;
						}
						p {
							font-size: 16px;
						}
					}
					.leiamais {
						display: flex;
						align-items: center;
						gap: 10px;
						justify-content: space-between;
						.data {
							font-size: 14px;
							display: flex;
							align-items: center;
							gap: 5px;
							transition: 0.2s;
						}
						p {
							font-size: 14px;
							font-weight: 600;
							color: #434343;
							text-align: left;
							transition: 0.2s;
							margin: 0;
						}
						svg,
						svg path {
							fill: #434343;
							transition: 0.2s;
						}
						a {
							display: flex;
							align-items: center;
							gap: 10px;
							text-decoration: none;
							&:hover {
								svg {
									animation: seta 1s infinite;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include mq(md) {
	.single {
		.content {
			&-post {
				padding-top: 150px;
				&-intro {
					width: 70%;
					margin: 0 auto;
				}
				&-text {
					p {
					}
					figure.wp-block-gallery.has-nested-images {
						figure.wp-block-image {
							align-items: center;
							img {
								max-width: 200px !important;
							}
						}
					}
				}
				&-outro {
					display: flex;
					justify-content: space-around;
					align-items: center;
					border-top: 1px solid #e3dbd8;
					border-bottom: 1px solid #e3dbd8;
					padding: 30px 0;
					&-categories {
						margin-bottom: unset;
						gap: 10px;
					}
					&-share {
						margin-top: unset;
					}
				}
			}
		}
	}
}
