.mobile-nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.25s ease;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 60px 15px 15px 15px;

  font-size: 1.5rem;
  @include mq(md) {
    display: none;
  }
  &.active {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    position: fixed;
  }
  a.btn-close-nav {
    position: absolute;
    right: 30px;
    top: 60px;
  }
  .menu li {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  a {
    padding: 8px 15px;
    color: var(--green);
    font-weight: 600;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(62, 111, 58, 0.3);
    border-radius: 40px;
    text-decoration: none;
    font-size: 1.2em;
    white-space: nowrap;
    width: fit-content;
  }
  .seja {
    background: none;
    padding-top: 0;
  }

  .nav-main {
    a.btn-search {
      display: none;
    }
  }
  .search-area {
    padding: 30px;
    input {
      border-radius: 50px;
      border: 1px solid #c7c7c7;
      padding: 10px 20px;
    }
  }
  .nav-social {
    padding: 30px 15px 15px 15px;
    color: var(--green);
    span {
      display: inline-block;
      margin-right: 30px;
    }
    a {
      display: inline-block;
      padding: 0 8px;
    }
  }
  .seja {
    color: var(--green);
  }
}
