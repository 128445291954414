.parceiro {
	&__ondeencontrar {
		padding: 60px 0;
		h2,
		h3,
		p {
			color: var(--green01);
		}
		&-btn {
			margin-top: 25px;
			a {
				font-size: em(16);
				background: var(--green01);
				border-radius: 100px;
				border: 2px solid var(--green01);
				text-align: center;
				padding: 17px 30px;
				color: var(--white);
				text-decoration: none;
				transition: all 0.2s;
				display: block;
				width: fit-content;
				&:hover {
					background: transparent;
					transition: all 0.2s;
					color: var(--green01);
				}
			}
		}
		&-img {
			transform: translateY(30px);
			text-align: center;
			img {
				filter: drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.25));
			}
		}
	}
}

@include mq(md) {
	.parceiro {
		&__ondeencontrar {
			padding: 110px 0 60px;
			.wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 20px 0px;
				width: 980px;
				position: relative;
			}
			&-title {
				grid-column: 2;
				grid-row: 1;
			}
			&-btn {
				grid-column: 2;
				grid-row: 2;
				margin-top: 0;
			}
			&-img {
				transform: translate(-50px, -150px);
				position: absolute;
				left: 0;
				img {
					width: 431px;
				}
			}
		}
	}
}
