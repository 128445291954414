.politica {
	.social {
		&__title,
		&__plugin,
		&__btn {
			display: none;
		}
	}
	.wrapper {
		height: 100%;
	}
	.bg-gradient {
		height: 85px;
	}
	&__topo {
		background: var(--green01);
		padding-top: 130px;
		padding-bottom: 30px;
		&-title {
			h1 {
				font-size: em(14);
				font-weight: 300;
				line-height: em(12);
				text-transform: uppercase;
				color: var(--white);
				margin-bottom: 5px;
				margin-top: -90px;
				padding-top: 90px;

				strong {
					font-weight: 700;
				}

				@include mq(md) {
					font-size: em(16);
				}
			}
			h2 {
				font-size: em(32);
				font-weight: 300;
				margin: 0 0 10px 0;
				line-height: 125%;
				color: var(--white);
				padding-top: 0;
				text-transform: unset;
				strong {
					font-weight: 700;
				}

				@include mq(md) {
					font-size: em(50);
				}
			}
		}
		&-img {
			text-align: center;
			margin-top: 30px;
			img {
				filter: drop-shadow(15px 15px 100px rgba(0, 0, 0, 0.25));
				max-width: 300px;
				width: 100%;
			}
		}
	}
}

@include mq(md) {
	.politica {
		&__topo {
			height: 421px;
			.wrapper {
				display: flex;
				align-items: center;
			}
			&-title {
				flex: 1;
			}
			&-img {
				transform: translate(0, 80px);
				margin-bottom: 0;
				flex: 1;
				img {
					max-width: 431px;
					width: unset;
				}
			}
		}
	}
}
