.conteudos-populares {

  &__lista {
    
    width: 100%;
    padding: 0px;
    position: relative;
    color: #00185B;
    margin-bottom: 35px;

    @include mq(md) {
      background: #fff;
    border-radius: 50px 0 0 50px;
    padding: 45px;
    }

    &:before {
      @include mq(md) {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='198.431' height='178.43' viewBox='0 0 198.431 178.43'%3E%3Cpath id='Caminho_706' data-name='Caminho 706' d='M178.431,178.43H-20c24.068,0,33.505-1.7,40.136-3.053a198.514,198.514,0,0,0,37.449-11.625,199.5,199.5,0,0,0,33.888-18.394A200.914,200.914,0,0,0,121,121a200.916,200.916,0,0,0,24.36-29.525,199.619,199.619,0,0,0,18.394-33.888,198.482,198.482,0,0,0,11.625-37.449A199.252,199.252,0,0,0,178.431,0Z' transform='translate(20)' fill='%23fff'/%3E%3C/svg%3E%0A");
    display: block;
    width: 56px;
    height: 50px;
    background-size: 100%;
    right: calc(-100vw + 1190px);
    top: -50px;
  }

  }

  &:after {
    @include mq(md) {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='198.431' height='178.43' viewBox='0 0 198.431 178.43'%3E%3Cpath id='Caminho_707' data-name='Caminho 707' d='M178.431,0H-20C-4.112,0,13.5,1.7,20.136,3.053A198.513,198.513,0,0,1,57.585,14.678,199.5,199.5,0,0,1,91.473,33.072,200.914,200.914,0,0,1,121,57.432a200.916,200.916,0,0,1,24.36,29.525,199.62,199.62,0,0,1,18.394,33.888,198.483,198.483,0,0,1,11.625,37.449,199.253,199.253,0,0,1,3.053,20.136Z' transform='translate(20)' fill='%23fff'/%3E%3C/svg%3E%0A");
    display: block;
    width: 56px;
    height: 50px;
    background-size: 100%;
    right: calc(-100vw + 1190px);
    bottom: -50px;
  }
  }

    .new-widget-area {
      overflow: visible;
      width: calc(100vw - 60px);

      @include mq(md) {
        overflow: auto;
      width: auto;
      
      }

      &:after {
        @include mq(md) {
      content: '';
      display: block;
      position: absolute;
      width: calc(100vw - 1190px);
      right: calc(-100vw + 1190px);
      top: 0;
      bottom: 0;
      background: #fff;
    }
    }
    }
    

  }

   &__ttl {
      margin-left: 45px;
   }

   a {
    text-decoration: none;
   }

   ul {
    counter-reset: section;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    padding: 0 30px 30px 30px;
    overflow: scroll;
    width: 100vw;
    margin-left: -30px;

    @include mq(md) {
      flex-direction: column;
      width: auto;
      padding: 0px;
      overflow: visible;
      width: 100%;
      margin: 0;
    }

    li {
      color: var(--blue04);
      background: #fff;
      border-radius: 30px;
      padding: 25px 30px;
      width: 300px;
      flex: 0 0 300px;
      display: inline-block;
      border: 0;
      margin: 0;

      @include mq(md) {
        background: transparent;
        border-radius: 0;
        padding: 0;
        width: auto;
        flex: 0 0 auto;
        border-bottom: 1px solid var(--dark-blue);
padding-bottom: 25px;
margin-bottom: 20px;
      }

      &:before {
        counter-increment: section;               /* Increment the section counter*/
        content: counter(section)"."; /* Display the counter */
        display: inline;
      }
    }

    .wpp-post-title {

    }

    .wpp-excerpt {
      color: var(--text-blue);
      display: block;
    }
   }





}