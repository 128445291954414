.consumidor {
	&__comofunciona {
		margin: 90px 0;
		h2,
		h3,
		p {
			color: var(--red01);
		}
		&-title {
			text-align: center;
			h3 {
				width: 50%;
				margin: 0 auto;
			}
		}
		&-aditivos {
			--animation-time: 0.2s;
			margin: 30px 0;
			.aditivo {
				&-cards {
					width: fit-content;
					.box-outside {
						display: flex;
						justify-content: center;
					}
				}
				&-divisor {
					display: flex;
					align-items: center;
					padding: 0 10px;
				}
				&-wrapper {
					scrollbar-width: none;
					overflow: scroll visible;
					padding: 0 15px;
				}
				&-card {
					transition: all var(--animation-time);
					height: inherit;
					background-size: 200% 0%;
					border-radius: 32px;
					padding: 15px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 187px;
					height: 223px;
					display: flex;
					position: relative;
					background: linear-gradient(
						90deg,
						#f05f70 12%,
						#c43c4a 100%
					);

					.box {
						display: flex;
						align-items: center;
						flex-direction: column;
						border-radius: 24px;
						height: 100%;
						justify-content: center;
						padding: 7px;
						svg {
							transition: all var(--animation-time);
							// transform: translateY(30px);
							transition-delay: var(--animation-time);
						}
						p {
							color: var(--white);
							opacity: 0;
							transition: opacity var(--animation-time)
									ease-in-out,
								max-height var(--animation-time) ease-in-out;
							text-align: center;
							visibility: hidden;
							overflow: hidden;
							margin: 0;
							font-size: em(12);
							max-height: 0;
						}
					}

					&:hover {
						transition: all var(--animation-time);
						.box {
							background: var(--red02);
							svg {
								transition: all var(--animation-time);
								transform: translateY(-10px);
							}

							p {
								opacity: 1;
								visibility: visible;
								transition: opacity var(--animation-time)
										ease-in-out,
									max-height var(--animation-time) ease-in-out;
								transition-delay: 0.3s;
								transform: translateY(10px);
								max-height: 999px;
							}
						}
					}
				}
			}
		}

		&-processo {
			h3 {
				text-align: center;
				font-size: em(25);
			}
			.wrapper-processo {
				scrollbar-width: none;
				overflow: scroll visible;
				padding: 0 20px;
			}

			&-boxes {
				width: fit-content;
				margin: 20px 0;
			}
			&-scroll {
				display: flex;
				grid-gap: 20px;
				.processo {
					position: relative;
					margin-bottom: 30px;
					--pl: 20px;
					width: 292px;
					height: 229px;
					img {
						border: 2px solid var(--red01);
						border-radius: 15px;
						padding: 12px;
						background: var(--white);
						position: absolute;
						top: 0;
						left: calc(var(--pl) + 10px);
					}
					p {
						border: 1px solid var(--red01);
						border-radius: 20px;
						padding: 75px 40px 55px var(--pl);
						height: 100%;
					}
				}
			}
		}
	}
}

@include mq(md) {
	.consumidor {
		&__comofunciona {
			&-aditivos {
				margin: 30px 0 60px;
				.aditivo {
					&-card {
						padding: 0;
						background: none;
						.box {
							padding: 10px;
						}
					}
					&-wrapper {
						max-width: em(1160);
						margin: 0 auto;
					}
					&-cards {
						width: 100%;
						margin: 0 auto;
						border-radius: 32px;
						background: linear-gradient(
							90deg,
							#f05f70 12%,
							#c43c4a 100%
						);
						padding: 15px 20px;
					}
					&-divisor {
						svg {
							width: 11px;
							path {
								fill: rgba(248, 248, 248, 0.8);
							}
						}
					}
				}
			}
			&-processo {
				&-boxes {
					margin: 20px auto;
					width: 100%;
				}
				&-scroll {
					.processo {
						flex: 1;
						width: unset;
						height: unset;
						p {
							font-size: 16px;
							padding: 75px 20px 0 var(--pl);
						}
					}
				}
			}
		}
	}
}
