.social {
	margin-top: 100px;
	.wrapper {
		padding: 0;
		@include mq(md) {
			padding: 0 20px;
		}
	}
	&__plugin {
		margin: 30px 0;
		#esf-insta-feed {
			.icon-esf-video-camera {
				top: 20px;
				right: 10px;
			}
			.esf-insta-multimedia {
				top: 10px;
				left: 7px;
				position: absolute;
			}
			.esf_insta_feeds_row {
				gap: 40px;
				overflow: scroll visible;
				scrollbar-width: none;
				flex-wrap: unset;
				@include mq(md) {
					overflow: initial;
					scrollbar-width: unset;
					gap: unset;
				}
				.esf_insta_row_box,
				.esf-insta-row-wrapper,
				.esf-insta-overlay {
					border-radius: 20px;
				}
				.esf-insta-overlay {
					.esf-insta-plus:before {
						content: "";
						display: none;
					}
				}
				.efbl-row-content {
					max-width: unset;
					@include mq(md) {
						justify-items: center;
					}
					.esf-insta-row-wrapper {
						padding: 0;
					}
				}
			}
			.esf_insta_load_more_btns_wrap {
				display: none;
			}
		}
	}
	&__title {
		text-align: center;
		h2,
		h3 {
			color: var(--red01);
		}
		h3 {
			padding: 0 20px;
			@include mq(md) {
				padding: unset;
			}
		}
		@include mq(md) {
			h3 {
				width: 50%;
				margin: 0 auto;
			}
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		margin: 30px 0;
		a {
			background: var(--red01);
			color: var(--white);
			width: 254px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid var(--red01);
			border-radius: 100px;
			font-size: 18px;
			text-decoration: none;
			transition: 0.2s;
			&:hover {
				background: transparent;
				transition: 0.2s;
				color: var(--red01);
			}
		}
	}
	&__selo {
		display: flex;
		justify-content: center;
		margin: 70px 0;
		img {
			filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.25));
			&:first-of-type {
				display: none;
				@include mq(md) {
					display: block;
				}
			}
			&:last-of-type {
				display: block;
				@include mq(md) {
					display: none;
				}
			}
		}
	}
}
.social__plugin #esf-insta-feed .esf_insta_feeds_row .esf-insta-row-wrapper,
.social__plugin #esf-insta-feed .esf_insta_feeds_row .esf_insta_row_box {
	width: 275px;
}
.social__plugin #esf-insta-feed .esf_insta_feeds_row .esf-insta-row-wrapper,
.social__plugin #esf-insta-feed .esf_insta_feeds_row .esf_insta_row_box {
	width: 275px;
}
.esf_insta_feeds_holder.esf_insta_feeds_row .efbl-row-content {
	max-width: unset;
}

.contato .social {
	margin-top: 50px;
}
