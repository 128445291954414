.politica {
	&__content {
		margin-top: 50px;
		h3 {
			font-size: 20px;
			color: var(--green01);
			margin-bottom: 0;
			line-height: 26px;
			&:last-of-type {
				font-size: 24px;
				&:before,
				&:after {
					content: "";
					height: 1px;
					display: block;
					width: 100%;
					background: #e3dbd8;
				}
				&:before {
					margin-bottom: 20px;
					margin-top: 70px;
				}
				&:after {
					margin-top: 20px;
				}
			}
		}
		p {
			color: var(--black);
			margin-top: 0;
			line-height: 26px;
		}
	}
}

@include mq(md) {
	.politica {
		&__content {
			margin-top: 130px;
			.wrapper {
				width: em(720);
			}
		}
	}
}
