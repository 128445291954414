.ondeencontrar {
	&__lista {
		margin: 50px 0;
		ul {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
			gap: 50px;
			justify-items: center;
			li {
				display: none;
				position: relative;
				&.active {
					display: block;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
				.icon-taxonomy {
					position: absolute;
					top: -25px;
					left: 55px;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					border: 2px solid var(--white);
					background: var(--green01);
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 2;
				}
				.ondeencontrar {
					&-info {
						display: flex;
						align-items: center;
						justify-content: space-evenly;
						flex-direction: column;
						margin: 0 auto;
						width: 280px;
						height: 280px;
						border: 3px solid var(--green01);
						border-radius: 25px;
						position: relative;
						text-align: center;
						padding: 0 20px;
						.marca-logo {
							width: 100px;
							height: 100px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 20px;
						}
						h3 {
							font-size: 20px;
							font-weight: 500;
							color: var(--green01);
							margin-bottom: 0;
						}
						.btn-saibamais {
							color: var(--white);
							background: var(--green01);
							font-size: 16px;
							font-weight: 400;
							text-align: center;
							width: 168px;
							height: 50px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 100px;
							transition: all 0.2s;
							border: 1px solid var(--green01);
							&:hover {
								transition: all 0.2s;
								background: transparent;
								cursor: pointer;
								color: var(--green01);
							}
						}
					}
					&-background {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						left: 0;
						opacity: 0;
						transition: 0.2s;
						z-index: -1;
						border-radius: 25px;
						display: flex;
						width: 280px;
						height: 280px;
						justify-content: space-evenly;
						flex-direction: column;
						padding: 30px 20px;
						margin: 0 auto;
						background: transparent;
						align-items: center;
						text-align: center;
						&.active {
							background: var(--green01);
							transition: 0.2s;
							opacity: 1;
							z-index: 1;
						}
						p,
						a {
							font-size: 14px;
							color: var(--white);
							text-decoration: none;
						}
						a {
							transition: 0.2s;
							&:hover {
								text-decoration: underline;
								transition: 0.2s;
							}
						}
						p {
							margin: 0;
						}
						a.link-mapa {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 6px;
							height: 40px;
							border: 1px solid #ffffff50;
							border-radius: 100px;
							margin-top: 10px;
							transition: 0.2s;
							padding: 0 15px;
							&:hover {
								background: #ffffff50;
								transition: 0.2s;
								color: var(--white);
							}
						}
					}
				}
			}
		}
	}
}

@include mq(md) {
	.ondeencontrar {
		&__lista {
			margin: 110px 0;
			ul {
				gap: 50px 20px;
				li {
					.icon-taxonomy {
						left: 30px;
					}
				}
			}
		}
	}
}
